import {Component, Inject, OnInit, NgIterable, ViewChild, ElementRef } from '@angular/core';
import {ActivatedRoute, ParamMap} from "@angular/router";
import {HttpClient, HttpParams} from "@angular/common/http";
import { AuthService } from 'src/app/auth/auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';
//import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-detail-train',
  templateUrl: './detail-train.component.html',
  styleUrls: ['./detail-train.component.css'],
  //providers: [DatePipe]
})
export class DetailTrainComponent implements OnInit{
  
  dataLoaded = false;
  wagonData : any;
  reasons : any;
  currentreason : any;
  public order:string | null = "";
  arrivaldate : any;
  departuredate : any;
  arrivaltime : any;
  departuretime : any;
  comment : any;

  parrivaldate : any;
  pdeparturedate : any;
  parrivaltime : any;
  pdeparturetime : any;
  userType : any = "";

  returnedQty : number = 0;

  private sm : SharedModule;
  //private dp : DatePipe;

  constructor (private route: ActivatedRoute,private http: HttpClient, @Inject('BASE_URL')private baseUrl:string, public shared:SharedModule, private _router : Router
  //,datePipe: DatePipe,
  ,auth:AuthService)
  {
    this.sm = shared;
   // this.dp = datePipe;
    this.userType = auth.currentUserValue?.usertype;
  }

  ngOnInit() {

    let counter : number = 0;
    
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.order = params.get('order');
      if (this.order) {
         counter ++;
         this.dataLoaded = counter == 4;
      }
    });

   
    let params = new HttpParams().set("trainID",this.order != null ? this.order : "")
    this.http.get(this.baseUrl + 'Rail/GetWagonData', {params:params} ).subscribe(
      (data:any) => {
        this.wagonData = data; 
        counter++;
        this.dataLoaded = counter == 4});

    
    this.http.get(this.baseUrl + 'Rail/GetReasons').subscribe(
          (data:any) => {
            this.reasons = data; 
            counter++;
            this.dataLoaded = counter == 4});
    
    this.http.get(this.baseUrl + 'Rail/GetTrainHeader', {params:params}).subscribe(
              (data:any) => {
                this.arrivaldate = null;//this.dp.transform(data[0].arrivalDate, 'yyyy-MM-dd');
                this.departuredate = null; //this.dp.transform(data[0].arrivalDate, 'yyyy-MM-dd');
                this.departuretime = "00:00";
                this.arrivaltime = "00:00";
                counter++;
                this.dataLoaded = counter == 4});
    

  }

  
  validate(formData: FormData) {
    let errorMessage:string = "";
    
    if (formData.get("Comment")?.toString().trim() == ""){
      errorMessage = "You are required to enter a comment.";
    }else if (this.arrivaldate == null){
      errorMessage = "Please enter a valid arrival date";
    }else if (this.departuredate == null){
      errorMessage = "Please enter a valid departure date";
    }
    else if ((this.returnedQty == null) || (this.returnedQty == 0)){
      errorMessage = "Please enter a valid Unloaded Qty, that can not be blank and must be greater than zero";
    }


   
    return errorMessage;
  }


  public saveFormData()
  {
    let formData:FormData = new FormData();
    formData.set("TrainID",this.order != null ? this.order : "");
    formData.set("ArrivalDate",this.arrivaldate != null ? this.arrivaldate : "");
    formData.set("DepartureDate",this.departuredate != null ? this.departuredate : "");
    formData.set("ArrivalTime",this.arrivaltime != null ? this.arrivaltime : "");
    formData.set("DepartureTime",this.departuretime != null ? this.departuretime : "");
    formData.set("Comment",this.comment != null ? this.comment : "");
    if (this.returnedQty != null)
      formData.set("ReturnedQty",this.returnedQty.toString());
    else
      formData.set("ReturnedQty","");
    
    let errorString = this.validate(formData);
    if (errorString.length == 0) {
      this.http
        .post(this.baseUrl + 'Rail/SaveTrain', formData)
        .subscribe({
          next: (response) => this.saveFinished(),
          error: (error) => this.sm.metroAlert("There was an error and your site settings have not been saved.<br/><br/>" + error.message, "Failed", true),
        });
      }else{
        this.shared.metroAlert(errorString, "The dialog contains errors", true);
      }
    
    
  }

  public saveWagonData(seq : string, value : string)
  {
    let formData:FormData = new FormData();
    formData.set("TrainID",this.order != null ? this.order : "");
    formData.set("Seq", seq != null ? seq : "0");
    formData.set("Reason",value != null ? value : "");
     this.http
         .post(this.baseUrl + 'Rail/SaveWagonReason', formData)
         .subscribe({
           
           error: (error) => this.sm.metroAlert("There was an error and your site settings have not been saved.<br/><br/>" + error.message, "Failed", true),
         });
  }

  saveFinished()
  {
    this.sm.metroAlert("Train Details saved.","Save Data");
    this._router.navigate(['/'])
  }
    
  onChange(id : any) {
    if (id.reason != '')
    this.saveWagonData(id.seq, id.reason);
}

 

}
