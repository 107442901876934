import {Component, Inject, OnInit} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import { AuthService } from 'src/app/auth/auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';
declare var $:any;

@Component({
  selector: 'app-resend',
  templateUrl: './resend.component.html',
  styleUrl: './resend.component.css'
})
export class ResendComponent implements OnInit  {
  private sm : SharedModule;
  userType : any = "";
  
  dataLoaded = false;
  completeload = false;

  woReference : string = "";

  lineID : string = "";
  status : string = "";

  sackLines = [
    {id: '1', text: 'Sack Line 1'},
    {id: '2', text: 'Sack Line 2'},
   ];

  constructor(private http: HttpClient,  @Inject('BASE_URL')private baseUrl: string, public auth:AuthService, public shared:SharedModule, private _router:Router) {
    this.sm = shared;
    this.userType = auth.currentUserValue?.usertype;
  }
 
  ngOnInit(){
    let counter : number = 0;
    this.dataLoaded = true;
    this.completeload = false;
    
  }

  public submitFormData()
  {
    let formData:FormData = new FormData();
    if ((this.woReference.length) > 0)
    {
      let params = new HttpParams().set("woNumber",this.woReference != null ? this.woReference : "")
      this.http.get(this.baseUrl + 'Navara/ResendSackLabel', {params:params} ).subscribe(
        (data:any) => {
          this.lineID = data[0].lineID;
          this.status = data[0].status;
          if (this.status == "NotFound")
            this.sm.metroAlert("Works Order Not Found","Title");
          else
            this.completeload = true;

         });
      
    }
    //this.saveFinished();
    
  }

  public submitReprint()
  {
    let formData:FormData = new FormData();
    if ((this.woReference.length) > 0)
    {
      let params = new HttpParams();
      params.set("woNumber",this.woReference != null ? this.woReference : "");
      params.set("sLine",this.lineID != null ? this.lineID : "");
      this.http.post(this.baseUrl + 'Navara/ResendSackLabelToKelgray',
        {
          "lineID" : this.lineID,
          "woNumber" : this.woReference
          }
       ).subscribe({
        next: (response) => this.saveFinished(),
        error: (error) => this.sm.metroAlert("There was an error and your changes have not been saved<br/><br/>" + error.message, "Failed", true),
      });
      
    }
    //this.saveFinished();
    
  }

  saveFinished()
  {
    this.sm.metroAlert("Save succesful.","Title");
    this._router.navigate(['/'])
  }

}
