import { Component, Inject } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {SharedModule} from "../../shared/shared.module";

@Component({
  selector: 'app-auction-terms',
  templateUrl: './auction-terms.component.html',
  styleUrls: ['./auction-terms.component.css']
})
export class AuctionTermsComponent {
  public pdfFilename:string = "";
  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string,private route: ActivatedRoute, public sharedFunctions: SharedModule) {

  }
  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.pdfFilename = this.pdfTypeToFilename("terms");

      let queryParams = new HttpParams()
        .set("pdfFilename", this.pdfFilename);
      this.http.get<any>(this.baseUrl + 'SiteSettings/GetPdf', {params: queryParams}).subscribe({
        next: (result: any) => {
          var blob = this.sharedFunctions.base64ToBlob(result.data, result.mimetype);
          var url = window.URL.createObjectURL(blob);
          $("#pdfSrc").attr("src", url);
        },
        error: (error: any) => {
          //console.error(error);
        }
      });
    });
  }

  pdfTypeToFilename(pdfType:string | null) : string
  {
    let ret:string = '';

    switch(pdfType?.toLowerCase().trim()){
      case "store":
        ret = "STORE_GUIDE";
        break;
      case "store-charge":
        ret = "STORE_CHARGES";
        break;
      case "base-price":
        ret = "BASE_PRICE";
        break;
      case "marketing":
        ret = "MARKETING_NEWS";
        break;
      case "variety":
        ret = "VARIETY_GUIDANCE";
        break;
      case "terms":
        ret = "TERMS_AND_CONDITIONS";
        break;
      case "new-tonnage":
        ret = "NEW_TONNAGE";
        break;
      case "commitment-terms":
        ret = "COMMITMENT_TERMS";
        break;
      case "commitment-arrangements":
        ret = "COMMITMENT_ARRANGEMENT";
        break;
    }

    return ret;
  }

}
