import {Component, Inject, OnInit, ViewChild, ElementRef} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { AuthService } from 'src/app/auth/auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-shiftrecords',
  templateUrl: './shiftrecords.component.html',
  styleUrls: ['./shiftrecords.component.css'],
  providers: [DatePipe]
})
export class ShiftrecordsComponent implements OnInit {
  @ViewChild('comments') comments!: ElementRef;
 
  dataLoaded = false;
  sm : any;
  userType : any = "";

  
  currentDate : any;

  selectedShift : any;
  shifts : any;
  selectedArea:any;
  areas : any;
  canContinue = true;
  dateOfrecord : any;
  today : any;
  bAllDataSaved:any;

  comment : any;

  private dp : DatePipe;

  constructor(private http: HttpClient,  @Inject('BASE_URL')private baseUrl: string, public auth:AuthService, public shared:SharedModule, private _router:Router, datePipe: DatePipe) {
    this.sm = shared;
    this.userType = auth.currentUserValue?.usertype;
    this.dp = datePipe;
  }

  
  ngOnInit(){
    let counter : number = 0;
    this.dateOfrecord = this.dp.transform(new Date(), 'yyyy-MM-dd');

    this.http.get(this.baseUrl + 'SiteSettings/GetShiftPatterns').subscribe(
        data => {this.shifts = data; counter++; this.dataLoaded = counter == 2;});

    this.http.get(this.baseUrl + 'Milling/GetAreaLocations').subscribe(
          data => {this.areas = data; counter++; this.dataLoaded = counter == 2;});

  }

  validate(formData: FormData) {
    let errorMessage:string = "";
    if ((this.selectedArea == null) || (this.selectedArea == ""))
      errorMessage = "An area must be selected";

    if ((this.selectedShift == null) || (this.selectedShift == ""))
      errorMessage = "A shift pattern must be selected";

    if ((this.comment == null) || (this.comment.trim() == ""))
      errorMessage = "A comment MUST be entered";
    
    //if ((parseInt(formData.get("bidAmount")?.toString() ?? "0") ?? 0) <= 0){
    //  errorMessage = "You must enter a positive value for your bid amount.";      
    //} else if ((parseInt(formData.get("bidAmount")?.toString() ?? "0") ?? 0) < AppSettings.MIN_BID_AMOUNT && !this.selectedIsForLots) {
    //  errorMessage = "The bid amount must be greater or equal to " + AppSettings.MIN_BID_AMOUNT;
    //} 
    return errorMessage;
  }

  public saveFormData()
  {
    this.comment = this.comments.nativeElement.value;

    let formData:FormData = new FormData();
    formData.set("Area",this.selectedArea);
    formData.set("Shift",this.selectedShift);
    formData.set("Comment",this.comment.trim());
    formData.set("Date",this.dateOfrecord);
    
    let errorString = this.validate(formData);

    if (errorString.length == 0) {
      this.http
        .post(this.baseUrl + 'Milling/SaveShiftRecord', formData)
        .subscribe({
          next: (response) => this.bAllDataSaved = true,
          error: (error) => this.sm.metroAlert("There was an error and your site settings have not been saved.<br/><br/>" + error.message, "Failed", true),
        });

        this.saveFinished()

    }else{
      this.sm.metroAlert(errorString, "The dialog contains errors", true);
    }


  }

  saveFinished()
  {
    this.sm.metroAlert("Information saved.","Save Data");
    this._router.navigate(['/'])
  }

}
