<div class="container" style="margin-bottom: 10px;">
  <div class="container" style="margin-bottom: 0px;">
    <div class="row">
      <div class="cell-lg-one-third">
        <h1>
          <img src={{siteImage}}/>
        </h1>
      </div>
      <div class="cell-lg-two-third">
        <div class="cell-lg-menu">
          <div style="text-align: right" *ngIf="this.auth.isLoggedIn">
            <button class="shortcut">
              <a (click)="logout()">
                <img src="/assets/icons/Exit.png">
                <span class="caption">Exit</span>
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr class="thin">
</div>
<div class="container" style="margin-bottom: 10px;"></div>
