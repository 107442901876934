<ng-container *ngIf="!dataLoaded">
    <div id="loading" style="width:100%; text-align: center"><br/><br/><small>Please Wait, we're getting your
        data...</small><br/><br/><img src="assets/images/spinner.svg"></div>
    </ng-container>
    
    <ng-container *ngIf="dataLoaded">
        <h1>Resend Works Order To Sack Line</h1>
        <form>
            <div class="row">
                <div class="cell-5">
                    <label>Works Order to Send</label>
                    <input id="woReference"  [(ngModel)]="woReference"  [ngModelOptions]="{standalone: true}" class="myInputs" autocomplete="off" data-role="input" style="text-transform:uppercase">
                </div>
            </div>
            <div class="row">
                <div class="cell">
                    <button class="button primary" (click)="submitFormData()">Search for Works Order</button>
                </div>
            </div>
        </form>

        <ng-container *ngIf="completeload">
            <form>
                <div class="row">
            <div class="cell-5">
                  <label>Sack Line Progress</label>
                  <select data-role="select" id="Line"   [(ngModel)]="lineID" [ngModelOptions]="{standalone: true}">
                      <option *ngFor="let c of sackLines" [ngValue]="c.id">{{c.text}}</option>
                  </select>
            </div></div>

            <div class="row">
                <div class="cell">
                    <button class="button primary" (click)="submitReprint()">Resend the Works Order</button>
                </div>
            </div>
        </form>
        </ng-container>


    </ng-container>

