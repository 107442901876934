import {Component, Inject} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { Router } from '@angular/router';
import {SharedModule} from "../../shared/shared.module";

@Component({
  selector: 'app-auction-sell',
  templateUrl: './auction-sell.component.html',
  styleUrls: ['./auction-sell.component.css']
})
export class AuctionSellComponent {
  loading: boolean = true;
  hasOutstandingSell: boolean = false;
  successItems: any[] = [];
  tableAuction: any;
  memberInfo: any = {};
  memberAddress: any;
  dataLoaded: boolean = false;
  hasOutstandingBuy: boolean = false;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string,private router: Router, public sharedFunctions: SharedModule) {
  }

  ngOnInit() {
    this.http.get<any>(this.baseUrl + 'Grain/Auction/GetAuctionInfo').subscribe({
      next: (result: any) => {
        this.memberInfo = result.auctionPrivileges;
        this.memberAddress = result.memberAddress;
        this.tableAuction = result.activeItems;
        this.successItems = result.successfulItems;

        let outstanding = result["outstandingAuctionRequests"] ;
        if (this.memberInfo.auctionCanSell == 'N') {
          for (const request of outstanding) {
            if (request.requestSell == 'Y') {
              this.hasOutstandingSell = true;
            }
            if (request.requestBuy == 'Y') {
              this.hasOutstandingBuy = true;
            }
          }
        }

        this.dataLoaded = true;
      },
      error: (error: any) => {

      }
    });

  }

  requestAccess() {
    let formData:FormData = new FormData();
    formData.append("requestAccess","true");
    formData.append("requestSelling","true");
    formData.append("requestBuying","false");

    this.http
      .post(this.baseUrl + 'Grain/Auction/RequestAuctionAccess', formData)
      .subscribe({
        next: (response) => this.completeRequest(),
        error: (error) => this.sharedFunctions.metroAlert("There was an error and your changes have not been saved<br/><br/>" + error.message, "Failed", true),
      });
  }

  completeRequest(){
    this.sharedFunctions.metroAlert("Thank you. Your request has been received, and you will be notified when access has been granted.", "Received", false);
    this.ngOnInit();
  }

  agreeToTerms() {
    this.http
      .put(this.baseUrl + 'Grain/Auction/AcceptTerms',"")
      .subscribe({
        next: (response) => this.ngOnInit(),
        error: (error) => this.sharedFunctions.metroAlert("There was an error and your changes have not been saved<br/><br/>" + error.message, "Failed", true),
      });
  }

  openInputForm() {
    this.router.navigate(['grain', 'auction', 'sell', 'create']);
  }

  cancelAuction(auctionItemID: any) {
    let formData:FormData = new FormData();
    formData.set("auctionID",auctionItemID);
    let localCount:any = 0;
    let localError : string = "";
    this.http
      .post(this.baseUrl + 'Grain/Auction/AuctionHasBids', formData)
      .subscribe((data) => {

          localCount = data;
          if (localCount > 0)
          {
            localError = "Bid Already Placed Unable to cancel";
          }

          if (localError.length == 0) {
            this.http
              .post(this.baseUrl + 'Grain/Auction/DeleteAuction', formData)
              .subscribe((data) => {this.reload()});

          }else{
            this.sharedFunctions.metroAlert(localError, "Auction Warning", true);
          }
        }
      );
  }

  private reload() {
    document.location.reload();
  }
}
