<div class="cell-lg-two-third">
  <div class="cell-lg-menu">
    <div align="right">
      <button class="shortcut">
        <a href="/mainweb/logout">
          <img src="/assets/icons/Exit.png"><span class="caption">Exit</span>
        </a>
      </button>
    </div>
  </div>
</div>

