<ng-container *ngIf="userType != 'ADMINISTRATOR'">
<H3>{{authenticateduser.memberextid}} - {{authenticateduser.membertradingname}}</H3>
<ng-container *ngIf="authenticateduser.memberextid != currentuser.memberextid">
  <span style="color: #7a0022">Current Account: {{currentuser.memberextid}} - {{currentuser.membertradingname}}</span>
  <br/><br/>
  <button (click)="endImitate()" class="button primary">End Selection</button>
</ng-container>

<table *ngIf="dataLoaded" class="table striped row-hover table-border" >
  <thead>
  <tr>
    <th>Member No</th>
    <th>Member Name</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let item of allMembers" [style.background-color]="shouldHighlight(item) ? 'lightgreen' : ''">
    <td>{{item.memberextid}}</td>
    <td>{{item.membertradingname}}</td>
    <td>
      <button *ngIf="!shouldHighlight(item)" (click)="imitateUser(item.memberid)"class="button primary">Select</button>
    </td>
  </tr>
  </tbody>
</table>
</ng-container>

<ng-container *ngIf="userType == 'ADMINISTRATOR'">
   <H3>{{authenticateduser.memberextid}} - {{authenticateduser.membertradingname}}</H3>
   <ng-container *ngIf="authenticateduser.memberextid != currentuser.memberextid">
    <span style="color: #7a0022">Current Account: {{currentuser.memberextid}} - {{currentuser.membertradingname}}</span>
    <br/><br/>
    <button (click)="endImitate()" class="button primary">End Selection</button>
   </ng-container>
   <table *ngIf="dataLoaded" class="table striped row-hover table-border" cellWrapper="true"  data-pagination="true" data-show-search="true">
    <thead>
    <tr>
      <th>Member No</th>
      <th>Member Name</th>
      <th></th>
      <th></th>

    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of allMembers" [style.background-color]="shouldHighlight(item) ? 'lightgreen' : ''">
      <td>{{item.memberextid}}</td>
      <td>{{item.membertradingname}}</td>
      <td>
        <button *ngIf="!shouldHighlight(item)" (click)="imitateUser(item.memberid)" class="button primary">Select</button>
      </td>
      <td>
        
      </td>
    </tr>
    </tbody>
  </table>
  </ng-container>
