<app-no-permission  *ngIf="userType == 'STANDARD_USER'"></app-no-permission>
<ng-container *ngIf="userType == 'ADMINISTRATOR'">
    <ul class="h-menu border bd-light" data-role="ripple" data-ripple-target="li">
        <li><a [routerLink]="['/milling/kelgrey']" [routerLinkActive]="['active']"><span class="mif-home icon"></span>Kelgrey Test Page</a></li>
    </ul>
    <div>
    <h1>Pallet Label</h1>
    <form>
        <div class="row">
            <div class="cell-5">
                <label>Works Order </label>
                <input id="woReference"  [(ngModel)]="woReference"  [ngModelOptions]="{standalone: true}" class="myInputs" autocomplete="off" data-role="input" style="text-transform:uppercase">
            </div>
        </div>
        <div class="row">
            <div class="cell-5">
                <label>Product Code</label>
                <input id="productCode"  [(ngModel)]="productCode"  [ngModelOptions]="{standalone: true}" class="myInputs" autocomplete="off" data-role="input" style="text-transform:uppercase">
            </div>
        </div>
        <div class="row">
            <div class="cell-5">
                <label>Product Description</label>
                <input id="productDescription"  [(ngModel)]="productDescription"  [ngModelOptions]="{standalone: true}" class="myInputs" autocomplete="off" data-role="input" style="text-transform:uppercase">
            </div>
        </div>
        <div class="row">
            <div class="cell-5">
                <label>Nett Weight</label>
                <input id="netWeight"  [(ngModel)]="netWeight"  [ngModelOptions]="{standalone: true}" class="myInputs" autocomplete="off" data-role="input" style="text-transform:uppercase">
            </div>
        </div>
        <div class="row">
            <div class="cell-5">
                <label>No of Bags on Pallet</label>
                <input id="bagsOnPallet"  [(ngModel)]="bagsOnPallet"  [ngModelOptions]="{standalone: true}" class="myInputs" autocomplete="off" data-role="input" style="text-transform:uppercase">
            </div>
        </div>
        <div class="row">
            <div class="cell-5">
                <label>Navara ID</label>
                <input id="navaraID"  [(ngModel)]="navaraID"  [ngModelOptions]="{standalone: true}" class="myInputs" autocomplete="off" data-role="input" style="text-transform:uppercase">
            </div>
        </div>
        <div class="row">
            <div class="cell">
                <button class="button primary" (click)="submitFormData()">Submit</button>
            </div>
        </div>
    
        </form>
</div>
</ng-container>

