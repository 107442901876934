<ul class="h-menu border bd-light" data-role="ripple" data-ripple-target="li">
    <li><a [routerLink]="['/']" [routerLinkActive]="['active']"><span class="mif-home icon"></span>Home</a></li>    
    <li><a [routerLink]="['/milling/testing/home']" [routerLinkActive]="['active']"><span class="mif-tools icon"></span>Testing Main Page</a></li>  
</ul>

<h1>GRN Receipt</h1>
<ng-container *ngIf="dataLoaded">
<form>
    <div class="row mb-2">
        <label class="cell-sm-2">Warehouse</label>
        <div class="cell-sm-10">
            <input id="warehouse" type="text" class="metro-input" value="WIPB1" [(ngModel)]="warehouse" [ngModelOptions]="{standalone: true}">
        </div>
    </div>

    <div class="row mb-2">
        <label class="cell-sm-2">Location</label>
        <div class="cell-sm-10">
            <input id="location" type="text" class="metro-input" [(ngModel)]="location" [ngModelOptions]="{standalone: true}">
        </div>
    </div>
    

    <div class="row mb-2">
        <label class="cell-sm-2">Weight</label>
        <div class="cell-sm-10">
            <input id="weight" type="number" class="metro-input" [(ngModel)]="weight" [ngModelOptions]="{standalone: true}">
        </div>
    </div>
   
    <div class="row mb-2">
        <label class="cell-sm-2">Reference</label>
        <div class="cell-sm-10">
            <input id="reference" type="text" class="metro-input" [(ngModel)]="poNumber" [ngModelOptions]="{standalone: true}">
        </div>
    </div>

    <div class="row mb-2">
        <label class="cell-sm-2">Reference Item</label>
        <div class="cell-sm-10">
            <input id="referenceItem" type="text" class="metro-input" [(ngModel)]="referenceItem" [ngModelOptions]="{standalone: true}">
        </div>
    </div>

    <div class="row">
        <div class="cell">
            <button class="button primary" (click)="saveFormData()">Save</button>
        </div>
    </div>
</form>
</ng-container>