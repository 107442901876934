import {Component, Inject, Injectable} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthService} from "../auth/auth.service";
import {first} from "rxjs";
declare var $:any;
declare var Metro:any;


@Component({
  selector: 'app-loginform',
  templateUrl: './loginform.component.html',
  styleUrls: ['./loginform.component.css']
})
export class LoginformComponent {
  form:FormGroup;

  constructor(public auth:AuthService, private fb:FormBuilder,private http:HttpClient,@Inject('BASE_URL') private baseUrl: string,private router: Router) {
    if (this.auth.isLoggedIn) {
      this.router.navigate(['/']);
    }
    this.form = this.fb.group({
      uname: ['', Validators.required],
      upass: ['', Validators.required]
    });

  }

  ngOnInit(){


  }
  validateForm() {
      $(".login-form").animate({opacity: 0});
  }

  login() {
    const val = this.form.value;

    if (val.uname && val.upass) {
      this.auth.login(val.uname, val.upass)
        .pipe(first())
        .subscribe({
          next:(data:any) => {
            if (data.statusCode == 401){
              this.metroAlert("Sorry your username or password is incorrect, please try again.","Login",true);
            }else if (data.statusCode == 201){
              this.router.navigate(['/login/change']);
            }else if (data.statusCode == 251){
              this.router.navigate(['/']);
            }else if (data.statusCode == 200){
              this.router.navigate(['/']);
            }else{
              this.metroAlert("Invalid Login Credentials","Error",true);
            }
          },
          error:(error:any) => {
            this.metroAlert("An unexpected error has happened, please report to your system administrator","Error",true);
          }
        });

    }
  }

  metroAlert(message:string, title:string, error:boolean = false, acceptFunction:any = null){
    let thisObj = this;
    Metro.dialog.create({
      title: title,
      clsDialog: error ? "alert" : "success",
      content: "<div>" + message + "</div>",
      actions: [
        {
          caption: "OK",
          cls: "js-dialog-close",
          onclick: acceptFunction
        }
      ]
    });
  }

}

