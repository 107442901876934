<ul class="h-menu border bd-light" data-role="ripple" data-ripple-target="li">
    <li><a [routerLink]="['/']" [routerLinkActive]="['active']"><span class="mif-home icon"></span>Home</a></li>      
</ul>
<h1>Stock Entry</h1>

<ng-container *ngIf="!dataLoaded">
    <div id="loading" style="width:100%; text-align: center"><br/><br/><small>Please Wait, we're getting your
        data...</small><br/><br/><img src="assets/images/spinner.svg"></div>
    </ng-container>
    

<ng-container *ngIf="dataLoaded">
    <form>
        <ng-container *ngIf="canContinue">
         
        <div class="row">
            <div class="cell-5">
                <label>Plant Area</label>
                <select data-role="select" id="Area"   [(ngModel)]="selectedArea" [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let c of locations" [ngValue]="c.code">{{c.description}}</option>
                </select>
            </div>
           
        </div>


        <div class="row">
            <div class="cell-5">
                <label>Level</label>
                <select data-role="select" id="Area"   [(ngModel)]="selectedArea" [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let c of locations" [ngValue]="c.code">{{c.description}}</option>
                </select>
            </div>
           
        </div>




        <div class="row">
            <div class="cell">
                <button class="button primary" (click)="saveFormData()">Save</button>
            </div>
        </div>
        </ng-container>

     
    </form>
</ng-container>