import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class UserImitate implements HttpInterceptor {
  private customVariable: string = 'your_variable_value';

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (localStorage.getItem("imitateUser")) {
      var user = localStorage.getItem("imitateUser") ?? '';
      // Clone the request and add a custom header or parameter
      const modifiedRequest = request.clone({
        setHeaders: {
          'X-Imitate-User': user,
        },
      });
      return next.handle(modifiedRequest);
    }else{
      return next.handle(request);
    }

    // Pass the modified request to the next handler

  }
}
