import {Component, Inject, OnInit} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import { AuthService } from 'src/app/auth/auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';
declare var $:any;


@Component({
  selector: 'app-dayreport2',
  templateUrl: './dayreport2.component.html',
  styleUrl: './dayreport2.component.css'
})

export class Dayreport2Component  implements OnInit {

  dataLoaded : boolean = false;
  reportDate : any
  reportLoaded : boolean = false;
  base64encodedpdf:any

  private sm : SharedModule;


  constructor(private http: HttpClient,  @Inject('BASE_URL')private baseUrl: string, public auth:AuthService, public shared:SharedModule, private _router:Router) {
    this.sm = shared;
     
  }

  ngOnInit(){
    let counter : number = 0;
    this.dataLoaded = true;  
    this.reportLoaded = false;
      
  }

  public showReport()
  {
    let isExcel: boolean =false;

    let params = {
      "reportName": "DayReport2",
      "isExcel": isExcel,
      "dateFrom": this.reportDate,
      "dateTo": this.reportDate
    };

    const requestOptions: Object = {
      //headers: headers,
      params: params
    }

    this.http.get<ReportDownload>(this.baseUrl + 'Reports/GetReport', requestOptions).subscribe({
      
      next: (result: ReportDownload) => {
        if (isExcel) {
          var blob = this.sm.base64ToBlob(result.data, result.mimetype);
          var url = window.URL.createObjectURL(blob);
          var a = document.getElementById('excelDownloadLink');
          // @ts-ignore
          a.href = url;
          // @ts-ignore
          a.download = result.filename;
          // @ts-ignore
          document.getElementById('excelDownload').style.display = 'block';

        } else {
          var blob = this.sm.base64ToBlob(result.data, result.mimetype);
          var url = window.URL.createObjectURL(blob);
          $("#pdfSrc").attr("src", url);
          
          // @ts-ignore
          //document.getElementById('view').setAttribute('src', 'data:application/pdf;base64,' + result.data);
        }
        // @ts-ignore
        //document.getElementById('loading').style.display = 'none';
        // @ts-ignore
        //document.getElementById('view').style.display = 'block';
      },
      error: (error: any) => {
        // @ts-ignore
        //document.getElementById('loading').style.display = 'none';
        // @ts-ignore
        //document.getElementById('view').style.display = 'block';
        // @ts-ignore
        //document.getElementById('view').setAttribute('src', '');
        this.sm.metroAlert("Sorry an error occured while running the report, please check the console.log output.", "Error", true);
        console.error(error);
      }
    });
  

  }




}

export interface ReportDownload {
  mimetype:string;
  filename:string;
  data:string;
}


