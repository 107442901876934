<div *ngIf="!loaded" id="loading" style="width:100%; text-align: center"><br/><br/><small>Please Wait, we're getting your data...</small><br/><br/><img src="assets/images/spinner.svg"></div>
<div class="container" style="padding:20px 20px 20px 20px;" *ngIf="loaded">
<div class="container-fluid " style="background-color:#F2F3F4; font-size:15px">
  <div class="row">
    <div class="cell-3"><b>Harvest Year:</b></div>
    <div class="cell-4"> {{SummaryDetails.trnHarvestYear}}</div>
    <div class="cell-2" style="text-align: right;"><b>Date</b></div>
    <div class="cell-3">{{SummaryDetails.trnDtNettWeight | date:'dd/MM/Y'}}</div>
  </div>
  <div class="row">
    <div class="cell-3"><b>Ticket Number / Sample Number:</b></div>
    <div class="cell-5">{{SummaryDetails.trnTicketNumber}} / {{SummaryDetails.trnSampleNumber}}</div>
  </div>
  <div class="row">
    <div class="cell-3"><b>Member Release Note Number:</b></div>
    <div class="cell-5">{{SummaryDetails.trnGrainPassportNumber}}</div>
  </div>
  <div class="row">
    <div class="cell-3"><b>Fixing Number:</b></div>
    <div class="cell-5">{{SummaryDetails.trnDelOrderNumber}}</div>
  </div>
  <div class="row">
    <div class="cell-12"><b>
      <hr>
    </b></div>
  </div>
  <div class="row no-gap">
    <div class="cell-2"><b>Members Name:</b></div>
    <div class="cell-5">{{SummaryDetails.trnMemberDesc}}</div>
  </div>
  <div class="row no-gap">
    <div class="cell-2"><b>Code:</b></div>
    <div class="cell-5">{{SummaryDetails.trnMemberCode}}</div>
  </div>
  
  <div class="row no-gap">
    <div class="cell-2"><b>Variety:</b></div>
    <div class="cell-5">{{SummaryDetails.trnVarietyDesc}}</div>
  </div>
  <div class="row no-gap">
    <div class="cell-2"><b>Collected From:</b></div>
    <div class="cell-5">{{SummaryDetails.memaddressadd1}}</div>
  </div>
  <div class="row no-gap">
    <div class="cell-2"><b></b></div>
    <div class="cell-5">{{SummaryDetails.memaddressadd2}}</div>
  </div>
  <div class="row no-gap">
    <div class="cell-2"><b></b></div>
    <div class="cell-5">{{SummaryDetails.memaddressadd3}}</div>
  </div>
  <div class="row no-gap">
    <div class="cell-2"><b></b></div>
    <div class="cell-5">{{SummaryDetails.memaddressadd4}}</div>
  </div>
  <div class="row no-gap">
    <div class="cell-2"><b></b></div>
    <div class="cell-5">{{SummaryDetails.memaddresstown}}</div>
  </div>
  <div class="row no-gap">
    <div class="cell-2"><b></b></div>
    <div class="cell-5">{{SummaryDetails.memaddresspcode}}</div>
  </div>
  <div class="row no-gap">
    <div class="cell-2"><b></b></div>
    <div class="cell-5">{{SummaryDetails.memaddresscounty}}</div>
  </div>
  <div class="row no-gap">
    <div class="cell-2"><b>Delivered To:</b></div>
    <div class="cell-5">{{SummaryDetails.storename}}</div>
  </div>
  <div class="row no-gap">
    <div class="cell-2"><b>Haulier:</b></div>
    <div class="cell-5">{{SummaryDetails.trnHaulierDesc}}</div>
    <div class="cell-2" style="text-align: right;"><b>Reg. No.</b></div>
    <div class="cell-3" style="padding-left:10px;">{{SummaryDetails.trnVehicleReg}}</div>
  </div>
  <div class="row no-gap">
    <div class="cell-2"><b>Comments:</b></div>
    <div class="cell-5">{{SummaryDetails.tctData}}</div>
  </div>
  <div class="row no-gap"><br></div>
  <div class="row no-gap"><br></div>
  <div class="row no-gap"><br></div>
  <div class="row no-gap">
    <div class="cell-5"><b>Detailed Lab Results</b></div>
    <div class="cell-2"><br></div>
    <div class="cell-2" style="text-align: right;"><b>Weight</b></div>
    <div class="cell-3" style="padding-left:10px;">{{sharedFunctions.ConvertWeightToDisplay(SummaryDetails.trnNettWeight)}}</div>
  </div>
  <div class="row">
    <div class="cell-12"><b>
      <hr>
    </b></div>
  </div>
  <div class="row no-gap">
    <div class="cell-3"><b>Description</b></div>
    <div class="cell-2"><b>Value</b></div>
    <div class="cell-2"></div>
    <div class="cell-1"></div>
    <div class="cell-2"></div>
  </div>
  <div class="row">
    <div class="cell-12"><b>
      <hr>
    </b></div>
  </div>

  <div class="row no-gap" *ngFor="let summary of SampleDetails">
    <div class="cell-3"> {{summary.ttsDescription}}</div>
    <div class="cell-2"> {{summary.ttsValue.toFixed(2)}}</div>
    <div class="cell-2"> </div>
    <div class="cell-1"> </div>
    <div class="cell-2"></div>
  </div>
  <div class="row no-gap"><br></div>
  
  <div class="row">
    <div class="cell-12"><b>
      <hr>
    </b></div>
  </div>
  <div class="row no-gap">
    <div class="cell-2"></div>
    <div class="cell-2">Total Deduction</div>
    <div class="cell-2">{{sharedFunctions.ConvertWeightToDisplay(deductionAsWeight)}}</div>
    <div class="cell-2"></div>
    <div class="cell-2"></div>
  </div>
  <div class="row no-gap">
    <div class="cell-2"></div>
    <div class="cell-2">Storage Weight</div>
    <div class="cell-2">{{sharedFunctions.ConvertWeightToDisplay(SummaryDetails.trnMemberStorageWeight)}}</div>
    <div class="cell-2"></div>
    <div class="cell-2"></div>
  </div>
  <div class="row no-gap">
    <div class="cell-6"></div>
    <div class="cell-2"></div>
    <div class="cell-2"></div>
  </div>
  <div class="row no-gap">
    <div class="cell-6"></div>
    <div class="cell-2"></div>
    <div class="cell-2"></div>
  </div>
  <div class="row no-gap"><br></div>
  <div class="row no-gap">
    <div class="cell-2"><b>Documents</b></div>
  </div>
  <div class="row">
    <div class="cell-12"><b>
      <hr>
    </b></div>
  </div>
  <div class="container" style="padding:20px 20px 20px 20px;">
    <ng-container *ngFor="let doc of documents">
    <a *ngIf="doc.tttObject=='SECOND_WEIGH'" (click)="linkOpen(doc.tttReference);"  class="tooltip" title="click to see ticket information" href="">Final Weight Ticket</a>
    </ng-container>
    <div *ngIf="documents.length == 0" class = "row"><div class="cell-12">No Documents Found</div></div>
  </div>
</div>
</div>
