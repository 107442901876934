import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface MenuItem {
  menuName: string;
  routerLink: string;
  iconClass: string | null;
  dataHotkey: string | null;
  subMenu: MenuItem[];
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private menuUrl = 'SiteSettings/getSiteMenu';
  constructor(private http: HttpClient,@Inject('BASE_URL')private baseUrl: string) {}

  getMenuData(): Observable<MenuItem[]> {
    return this.http.get<MenuItem[]>(this.baseUrl + this.menuUrl);
  }

}
