import {Component, Inject, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { AuthService } from 'src/app/auth/auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';
declare var $:any;


@Component({
  selector: 'app-activeconnections',
  templateUrl: './activeconnections.component.html',
  styleUrls: ['./activeconnections.component.css']
})
export class ActiveconnectionsComponent implements OnInit{
  dataLoaded = false;
  Milltower : any;
  Pellet : any;
  Flaker : any;
  THWPacking : any;
  THWFlour : any;
  UmbraLine1 : any;
  UmbraLine2 : any;
  Wrapper : any;
  Efacs : any;
  FileExporter : any;
  


  private sm : SharedModule;

  constructor(private http: HttpClient,  @Inject('BASE_URL')private baseUrl: string, public auth:AuthService, public shared:SharedModule, private _router:Router) {
    this.sm = shared;
  }

  ngOnInit(){
    
    this.http.get(this.baseUrl + 'Milling/ListPLCStatus').subscribe(
      (data:any) => {
         
         this.Milltower = data[0].milltower == "1" ? "ONLINE" : "OFFLINE";
         this.Pellet = data[0].pellet == "1" ? "ONLINE" : "OFFLINE";
         this.Flaker = data[0].flaking == "1" ? "ONLINE" : "OFFLINE";
         this.THWPacking = data[0].packing == "1" ? "ONLINE" : "OFFLINE";
         this.THWFlour = data[0].thwFlour == "1" ? "ONLINE" : "OFFLINE";
         this.UmbraLine1 = data[0].umbra1 == "1" ? "ONLINE" : "OFFLINE";
         this.UmbraLine2 = data[0].umbra2 == "1" ? "ONLINE" : "OFFLINE";
         this.Wrapper = data[0].wrapper == "1" ? "ONLINE" : "OFFLINE";
         this.Efacs = "ONLINE";
         this.FileExporter = "ONLINE";
         this.dataLoaded = true;
       });

   
        
  }
}
