import { Component } from '@angular/core';
import {MenuItem, MenuService} from '../menu.service';
@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent {
  menuData: MenuItem[] = [];

  constructor(private menuService: MenuService) {
  }
  ngOnInit(): void {
    this.menuService.getMenuData().subscribe((data) => {
      this.menuData = data;
    });
  }
}
