<ng-container *ngIf="dataLoaded">
  <app-error-page *ngIf="memberInfo.auctionVisible == 'N'"></app-error-page>
  <ng-container *ngIf="memberInfo.auctionVisible == 'Y'">
    <ng-container *ngIf="memberInfo.auctionCanSell == 'N'">
      <br/><br/>
      <ng-container *ngIf="this.hasOutstandingSell">
        <div style="text-align: center">
          <h3>You have requested access to this area.<br/>The request is awaiting approval.</h3><br/>
        </div>
      </ng-container>
      <ng-container *ngIf="!this.hasOutstandingSell">
        <div style="text-align: center">
          <h3>You don't have access to this section of the Auction module.</h3><br/>
          <button class="button primary" (click)="requestAccess()">Request Access</button>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="memberInfo.auctionCanSell == 'Y'">
      <ng-container *ngIf="memberInfo.auctionAccepted == 'N'">
        <div style="text-align: center">
          <h2>Welcome...</h2>
          <h4>Please confirm you agree with the terms and conditions</h4>
          <a [routerLink]="['/auction','terms']">To view the terms and conditions please click here</a><br/><br/>
          <button class="button primary" (click)="agreeToTerms()">I agree</button>
        </div>
      </ng-container>
      <ng-container *ngIf="memberInfo.auctionAccepted == 'Y'">
        <div class="row">
          <div class="cell flex-align-self-start">
            <div class="row flex-align-start">
              <div class="row">
                <div class="cell">
                  <button class="button primary" (click)="openInputForm()">Create New Auction</button>
                </div>
              </div>
            </div>

            <table class="table striped row-hover table-border" id="auctionTable">
              <thead>
              <tr>
                <th class="sortable-column sort-asc">Auction ID</th>
               <!-- <th class="sortable-column sort-asc">No. Of Lots</th>-->
                <th>Total</th>
                <th>Auction Start</th>
                <th>Auction End</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of tableAuction">
                <!--<td>{{item.auctionLotSize}}</td>-->
                <td>{{item.auctionDisplayID}}</td>
                <td>{{item.auctionTotalSpace}}</td>
                <td>{{item.auctionStart | date:'dd-MMM-Y'}}</td>
                <td>{{item.auctionEnd | date:'dd-MMM-Y'}}</td>
                <td>
                  <button  *ngIf="dataLoaded && item.auctionhasSold == 'N' && item.auctionCurrentBid == 0" class="button primary" (click)="cancelAuction(item.auctionItemID)">Remove</button>
                </td>
              </tr>
              </tbody>
            </table>
            <app-auction-table [rows]="this.successItems" [title]="'Successful Auctions'" [dataLoaded]="this.dataLoaded"></app-auction-table>
          </div>


        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

