import {Component, Inject, OnInit} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import { AuthService } from 'src/app/auth/auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';
declare var $:any;


@Component({
  selector: 'app-packingdata',
  templateUrl: './packingdata.component.html',
  styleUrls: ['./packingdata.component.css']
})
export class PackingdataComponent  implements OnInit {
  dataLoaded = false;
  dataLoaded1 = false;

  palletRef : string = '';
  
  worksOrders : any;
  locations : any;
  selectedWO = "";
  selectedValue ="";
  txtProjectOwner = "";
  worksOrder : any;

  status : any;
  woOrder : any;
  partID : any;
  partDesc : any;
  warehouse : any;
  location : any;
  batchNo : any;
  qty : any;
  locations1 : any;
  selectedValue1 ="";
  alreadyComplete : any;
  
  private sm : SharedModule;
  
  constructor(private http: HttpClient,  @Inject('BASE_URL')private baseUrl: string, public auth:AuthService, public shared:SharedModule, private _router:Router) {
    this.sm = shared;
  }

  ngOnInit(){
    let counter : number = 0;
    this.dataLoaded = true;
    //this.http.get(this.baseUrl + 'Milling/ListOpenWorksOrders').subscribe(
    //  (data:any) => {
    //    this.worksOrders = data; 
    //    this.selectedWO =""; 
    //    counter++;
    //    this.dataLoaded = counter == 1;});
  }


  public GetFormData()
  {
    let counter : number = 0;
    let formData:FormData = new FormData();
    this.palletRef = $('#palletID').val();
    this.alreadyComplete = false;

    if (this.palletRef.startsWith('{'))
    {
      if (this.palletRef.endsWith('}'))
        this.palletRef = this.palletRef.substring(2, this.palletRef.length -1);
      else
        this.palletRef = this.palletRef.substring(2, this.palletRef.length);
    }
    
    let params = new HttpParams().set("sPalletID",this.palletRef != null ? this.palletRef : "")
    this.http.get(this.baseUrl + 'Navara/GetWOInfoFromPallet', {params:params} ).subscribe(
      (data:any) => {
         this.status = data[0].status;
         this.selectedWO = data[0].woOrder;
         this.partID = data[0].partid;
         this.partDesc = data[0].partdesc;
         this.warehouse = data[0].warehouseid;         
         this.batchNo = data[0].batchnumber;
         this.alreadyComplete = data[0].alreadycomplete;
         this.qty = Number(data[0].quantity);
         counter++;

         if (!this.alreadyComplete && this.status != "notfound")
         {
          this.status = "Possibly a Duplicate, speak to Shift Manager"
         }
         else if (this.status == "notfound")
         {
          this.status = "Pallet ID not found in database, speak to Shift Manager"
         }
         this.dataLoaded1 = counter == 2;
       });
   
       this.http.get(this.baseUrl + 'Milling/ListPackingLocations').subscribe(
        (data:any) => {
          this.locations = data; 
          this.selectedValue =""; 
          counter++;
          this.dataLoaded1 = counter == 2;});
         
  }


  validate(formData: FormData) {
    let errorMessage:string = "";
    if ((parseInt(formData.get("Weight")?.toString() ?? "0") ??0) > 100)
      errorMessage = "Weight too high";
    else if (((parseInt(formData.get("NoOfBags")?.toString() ?? "0") ??0) == 0) &&  ((parseFloat(formData.get("Weight")?.toString() ?? "0") ??0) == 0))
      errorMessage = "You MUST enter either a number of bags or weight";
    return errorMessage;
  }


  public saveFormData()
  {
    let formData:FormData = new FormData();
    formData.set("EventSource",'UMBRA');
    formData.set("WorksOrder",this.selectedWO);
    formData.set("NoOfBags",$('#NoOfBags').val() != '' ? $('#NoOfBags').val() : '');
    formData.set("Weight",$('#weight').val() != '' ? $('#weight').val() : null);
    formData.set("Warehouse","WIPB1");
    formData.set("Location",this.selectedValue);
    formData.set("PartID",'');
    formData.set("PalletID",this.palletRef != '' ? this.palletRef : '');
    formData.set("ContainerID",$('#ContainerID').val() != '' ? $('#ContainerID').val() : '');
    
    
    let errorString = this.validate(formData);

    if (errorString.length == 0) {
      this.http
        .post(this.baseUrl + 'Navara/SavePackingRecord', formData)
        .subscribe({
          next: (response) => this.saveFinished(),
          error: (error) => this.sm.metroAlert("There was an error and your changes have not been saved<br/><br/>" + error.message, "Failed", true),
        });

    }else{
      this.sm.metroAlert(errorString, "The dialog contains errors", true);
    }
    
  }


  saveFinished()
  {
    this.sm.metroAlert("Save succesful.","Title");
    this._router.navigate(['/'])
  }
}
