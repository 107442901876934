<form class="login-form bg-white p-6 mx-auto border bd-default win-shadow" data-role="validator"
      data-clear-invalid="2000" data-on-validate-form="validateForm()"
      [formGroup]="form"
      >
  <span class="place-right" style="margin-top: -10px;"><img
  src="/assets/images/logo.png"></span>
  <h2 class="text-light">Sign In</h2>
  <hr class="thin mt-4 mb-4 bg-white">
  <div class="form-group"><input type="text" formControlName="uname" name="uname" data-role="input" data-prepend="" placeholder="User Name"
                                 data-validate="required minlength=6"></div>
  <div class="form-group"><input type="password" formControlName="upass" name="upass" data-role="input" data-prepend="" placeholder="Password"
                                 data-validate="required minlength=6"></div>
  <div class="form-actions">
    <button class="button primary" style="width:100%" (click)="login()">Sign In</button>
  </div>
</form>
