import {Component, Inject} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { AuthService } from 'src/app/auth/auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';
declare var $:any;

@Component({
  selector: 'app-weight-off',
  templateUrl: './weight-off.component.html',
  styleUrls: ['./weight-off.component.css']
})
export class WeightOffComponent {
  private sm : SharedModule;

  constructor(private http: HttpClient,  @Inject('BASE_URL')private baseUrl: string, public auth:AuthService, public shared:SharedModule, private _router:Router) {
    this.sm = shared;
  }

  validate(formData: FormData) {
    let errorMessage:string = "";
    if ((parseInt(formData.get("weight")?.toString() ?? "0") ??0) > 100)
      errorMessage = "Weight too high";

    //if ((parseInt(formData.get("bidAmount")?.toString() ?? "0") ?? 0) <= 0){
    //  errorMessage = "You must enter a positive value for your bid amount.";      
    //} else if ((parseInt(formData.get("bidAmount")?.toString() ?? "0") ?? 0) < AppSettings.MIN_BID_AMOUNT && !this.selectedIsForLots) {
    //  errorMessage = "The bid amount must be greater or equal to " + AppSettings.MIN_BID_AMOUNT;
    //} 
    return errorMessage;
  }


  public saveFormData()
  {
    let formData:FormData = new FormData();
    formData.set("EventSource",$('#eventSource').val() != '' ? $('#eventSource').val() : '');
    formData.set("Warehouse",$('#warehouseOn').val() != '' ? $('#warehouseOn').val() : '');
    formData.set("Location",$('#locationOn').val() != '' ? $('#locationOn').val() : '');
    formData.set("Weight",$('#weight').val() != '' ? $('#weight').val() : 0);
    formData.set("Reference",$('#reference').val() != '' ? $('#reference').val() : '');
    formData.set("ReferenceItem",$('#partID').val() != '' ? $('#partID').val() : '');

    let errorString = this.validate(formData);

    if (errorString.length == 0) {
      this.http
        .post(this.baseUrl + 'Navara/SaveWeightOff', formData)
        .subscribe({
          next: (response) => this.saveFinished(),
          error: (error) => this.sm.metroAlert("There was an error and your changes have not been saved<br/><br/>" + error.message, "Failed", true),
        });
      //this.reload();  

    }else{
      this.sm.metroAlert(errorString, "The dialog contains errors", true);
    }
    
  }

  saveFinished()
  {
    this.sm.metroAlert("We have saved the form.","Title");
    this._router.navigate(['/milling/testing/home'])
  }
}
