import {Component, Inject} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { AuthService } from 'src/app/auth/auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import {NavigationExtras} from "@angular/router";
import {AppSettings} from "../../shared/shared.module";
import {interval, Subscription} from "rxjs";


@Component({
  selector: 'app-stockentry',
  templateUrl: './stockentry.component.html',
  styleUrl: './stockentry.component.css'
})
export class StockentryComponent {
  dataLoaded = true;

  canContinue = true;
  comments = "";
  locations : any;
  selectedValue: any;
  arrivaldate : any;
  selectedArea:any;
  username : any;

  constructor(private http: HttpClient,  @Inject('BASE_URL')private baseUrl: string, public auth:AuthService, public shared:SharedModule, private _router:Router,private route: ActivatedRoute) {
    this.username = auth.currentUserValue?.usertype;
  }

  public saveFormData()
  {
    let formData:FormData = new FormData();
  }
}
