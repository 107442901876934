import {Component, Inject, NgIterable} from '@angular/core';
import {ActivatedRoute, ParamMap} from "@angular/router";
import {HttpClient, HttpParams} from "@angular/common/http";
import {TodaysLoadsComponent} from "../home/components/todays-loads/todays-loads.component";
import { SharedModule, SummaryTable, summaryTable, } from 'src/app/shared/shared.module';
import { formatDate} from '@angular/common';

@Component({
  selector: 'app-show-summary',
  templateUrl: './show-summary.component.html',
  styleUrls: ['./show-summary.component.css']
})
export class ShowSummaryComponent {
  public year:string | null = "";
  public grades:SummaryTable = {};
  public mAddress : any;

  constructor(private route: ActivatedRoute,private http: HttpClient, @Inject('BASE_URL')private baseUrl:string,public sharedFunctions: SharedModule) {

  }

  ngOnInit() {
   // this.route.paramMap.subscribe((params: ParamMap) => {
      this.year = "2023";
      if (this.year) {
        this.grades = {};
        let queryParams = new HttpParams()
          .set("year", this.year.toString());
        // @ts-ignore
        document.getElementById('loading')
          .style.display = 'block';
        this.http.get<SummaryTable>(this.baseUrl + 'Grain/GetSummaryDatav2', {params: queryParams}).subscribe({
          next: (result: SummaryTable) => {
            this.grades = result;
            // @ts-ignore
            document.getElementById('loading')
              .style.display = 'none';
          },
          error: (error: any) => {
            console.error(error);
          }
        });

        this.http.get(this.baseUrl + 'Grain/GetMemberAddress').subscribe(
          (data:any) => {
            this.mAddress = data[0];
        });
      }
    //});
  }

  getTotal(value: summaryTable[], propertyName: string):number {
    let ret:number = 0;
    value.forEach(function(item){
      // @ts-ignore
      ret += parseFloat(item[propertyName]);
    });
    return ret;
  }
}
