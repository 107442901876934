<form name="edit" id="edit" class="" action="" method="post" ngForm="edit">
  <div class="form-group">
    <div class="row">
      <div class="cell-12">
        <label>Select Auction</label>
        <ng-container *ngIf="!dataLoaded">
        <select name="auction" id="auction" #auction data-role="select">
          <optgroup label="Active Auctions">
            <option *ngFor="let item of auctionDates" [value]="item.auctionID">From: {{  item.auctionStart | date:'dd-MMM-yyyy' }} To: {{  item.auctionEnd | date:'dd-MMM-yyyy' }}</option>
          </optgroup>
        </select>
        </ng-container>
      </div>

    </div>
    <div class="row">
      <div class="cell-6">
        <label>I'm selling lots</label>
        <select #lotTypeSelect id="lotType" name="lotType" data-role="select" (change)="typeChanged()">
          <option value="1">Space allocated into lots.</option>
          <option value="2">A defined amount of space</option>
        </select>
      </div>

      <div class="cell-3">
        <div [hidden]="lotType != 2">

          <label>Total Space</label>
          <input type="number" id="totalSize" #totalSize class="myInputs" autocomplete="off" data-role="input" min="0"
                 max="{{availableSpace}}" value="0" (keyup)="calcValue()" (change)="calcValue()" >

        </div>
        <div [hidden]="lotType != 1">
          <label>Number of lots</label>
          <input type="number" class="myInputs" #noLots id="noLots" (keyup)="calcValue()" (change)="calcValue()" autocomplete="off"
                 data-role="input" min="0" max="9999" value="0">
        </div>
      </div>
      <div class="cell-3">
        <div [hidden]="lotType != 1">
          <label>Lot Size (Tonnes)</label>
          <input type="number" class="myInputs" #lotSize id="lotSize" (keyup)="calcValue()" (change)="calcValue()" autocomplete="off"
                 data-role="input" min="0" max="9999" value="0">
        </div>
      </div>

    </div>

    <div class="cell-12">
      <B><label>Total Space</label>&nbsp;&nbsp;<span
        [ngStyle]="{'color':availableSpace < calcSpace ? 'red' : ''}">{{calcSpace}}</span> of {{availableSpace}}
      </B>
    </div>

    <hr>
    <div class="row">
      <div class="cell-4">
        <label>Do you want to set a reserve?</label>
        <select data-role="select" id="hasReserve" #hasReserveSelect name="hasReserve" [(ngModel)]="hasReserve">
          <option value="1">Yes</option>
          <option value="0">No</option>
        </select>
      </div>
      <div class="cell-4">
        <label>Reserve (£)</label>
        <input  [attr.disabled]="hasReserve == '0' ? 'disabled' : null" type="number" id="auctionReserve" #auctionReserve class="myInputs" autocomplete="off" data-role="input" min="0"
                max="9999" value="0">
      </div>
      <div class="cell-4">
        <label>Display reserve to buyers?</label>
        <select  [attr.disabled]="hasReserve == '0' ? 'disabled' : null" data-role="select" id="auctionShowReserve" #auctionShowReserve name="auctionShowReserve">
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="cell-8">
        <label>Do you want to set a buy it now price?</label>
        <select data-role="select" name="buyItNow" [(ngModel)]="buyItNow">
          <option value="1">Yes</option>
          <option value="0">No</option>
        </select>
      </div>
      <div class="cell-4">
        <label>Buy it now price (£)</label>
        <input [attr.disabled]="buyItNow == '0' ? 'disabled' : null" type="number" id="auctionBuyItNow" #auctionBuyItNow class="myInputs" autocomplete="off" data-role="input" min="0"
               max="9999" value="0">
      </div>
    </div>
    <div class="row">
      <div class="cell-4">
        <label>Display My Name to buyers</label>
        <select data-role="select" name="auctionDisplayName" #auctionDisplayName id="auctionDisplayName">
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </div>
      <!--
      <div class="cell-4">

        <label>Display Tonnage to buyers</label>
        <select data-role="select" name="auctionDisplayTonnage" #auctionDisplayTonnage id="auctionDisplayTonnage">
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      
      </div>
      -->
      <div class="cell-4">
        <label>Keep listing until sold.</label>
        <select data-role="select" name="auctionKeepListing" id="auctionKeepListing" #auctionKeepListing>
          <option value="true" selected="selected">Yes</option>
          <option value="false">No</option>
        </select>
      </div>
      <div class="cell-4"></div>
    </div>
  </div>
  <div class="row">
    <div class="cell-12">
      <label>
        <input type="checkbox" id="acceptTerms-sell">
        Please confirm you agree to the Terms and conditions as layout out <a target="_blank" [routerLink]="['/grain/auction/terms']">here</a>
      </label>
    </div>
  </div>

  <div class="dialog-actions">
    <button class="button" type="reset" value="Reset" (click)="cancelForm()">Cancel</button>
    <button class="button primary" (click)="saveRecord()">Save</button>
  </div>
</form>
