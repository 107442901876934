import {Component, Inject, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { AuthService } from 'src/app/auth/auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';
declare var $:any;

@Component({
  selector: 'app-sacklabel',
  templateUrl: './sacklabel.component.html',
  styleUrl: './sacklabel.component.css'
})
export class SacklabelComponent implements OnInit  {
  private sm : SharedModule;
  userType : any = "";
  
  dataLoaded = false;
  dataLoaded1 = false;

  woReference : string = '';

  isError = false;
  errorMessage: string = '';
  productCode: string = '';
  productDescription : string = '';
  //"productionDate": "2024-10-09T08:18:13.613Z",
  //"expiryDate": "2024-10-09T08:18:13.613Z",
  netWeight: string = "0";
  bagsOnPallet : string = "0";
  navaraID : string = "0";
  gS1: string = "";

  constructor(private http: HttpClient,  @Inject('BASE_URL')private baseUrl: string, public auth:AuthService, public shared:SharedModule, private _router:Router) {
    this.sm = shared;
    this.userType = auth.currentUserValue?.usertype;
  }
 
  ngOnInit(){
    let counter : number = 0;
    this.dataLoaded = true;
    //this.http.get(this.baseUrl + 'Milling/ListOpenWorksOrders').subscribe(
    //  (data:any) => {
    //    this.worksOrders = data; 
    //    this.selectedWO =""; 
    //    counter++;
    //    this.dataLoaded = counter == 1;});
  }


  public submitFormData()
  {
    let formData:FormData = new FormData();
    this.saveFinished();
    
  }

  saveFinished()
  {
    this.sm.metroAlert("Save succesful for : " + this.woReference ,"Title");
    this._router.navigate(['/milling/kelgrey/'])
  }
}
