import {Component, Inject} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { AuthService } from 'src/app/auth/auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import {NavigationExtras} from "@angular/router";
import {AppSettings} from "../../shared/shared.module";
import {interval, Subscription} from "rxjs";

declare var $:any;

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrl: './user.component.css'
})
export class UserComponent {
  userType : any = "";
  allUsers: any;
  dataLoaded : boolean = false;

  constructor(private http: HttpClient,  @Inject('BASE_URL')private baseUrl: string, public auth:AuthService, public shared:SharedModule, private _router:Router,private route: ActivatedRoute) {
    this.userType = auth.currentUserValue?.usertype;
  }

  ngOnInit() {
    if (this.userType == 'ADMINISTRATOR')
    {
        this.http.get<any>(this.baseUrl + 'Authentication/Listusers').subscribe({
          next: (result: any) => {
            this.allUsers = result.users;
            this.dataLoaded = true;
          },
          error: (error: any) => {

          }
        });
    }
    
  }


  deleteUser (userID : string)
  {
    let formData:FormData = new FormData();
    formData.append("userID",userID);
    
    this.http
        .post(this.baseUrl + 'Authentication/DeleteUser', formData)
        .subscribe({
          next: (response) => this.deleteFinished(),
          error: (error) => this.shared.metroAlert("There was an error and your changes have not been saved<br/><br/>" + error.message, "Failed", true),
        });


  }

  deleteFinished()
  {
    this.shared.metroAlert("Delete succesful.","Title");
    this._router.navigate(['/'])
  }


}
