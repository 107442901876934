import {Component, Inject} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {formatDate} from "@angular/common";
import {ActivatedRoute, Route, Router} from "@angular/router";
import {TodaysLoadsComponent} from "../home/components/todays-loads/todays-loads.component";

declare var myFunction:any;
declare var Metro:any;
@Component({
  selector: 'app-daily-loads',
  templateUrl: './daily-loads.component.html',
  styleUrls: ['./daily-loads.component.css']
})

export class DailyLoadsComponent {
  currentDate: Date = new Date();
  preset:string = "";

  constructor(private http: HttpClient, @Inject('BASE_URL')private baseUrl: string,public route: ActivatedRoute, public router: Router) {

  }

  ngOnInit(){
    this.route.paramMap.subscribe(param => {
      const selectedDate = param.get('selectedDate')
      if (selectedDate){
        let formattedDate:Date | null = TodaysLoadsComponent.ParamToDate(selectedDate);
        this.currentDate = formattedDate ?? new Date();
      }
      if (this.preset == "") {
        this.http.get<Date[]>(this.baseUrl + 'Grain/GetLoadDates').subscribe({
          next: (result: Date[]) => {
            var calendar = Metro.getPlugin('#calendar', 'calendar');
            this.preset = "";
            for (const item of result) {
              this.preset += formatDate(item, 'yyyy/MM/dd', 'en') + ",";
            }
            calendar.setPreset(this.preset);
            calendar.setShow(formatDate(this.currentDate, 'yyyy/MM/dd', 'en'));
            let thisRouter = this.router;
            let thisDayClicked = this.dayClicked;
            calendar.options.onDayClick = function (sel: any, day: any, el: any) {
              thisDayClicked(sel, day, el, thisRouter);
            };
          },
          error: (error: any) => {
            console.error(error);
          }
        });
      }else{
        var calendar = Metro.getPlugin('#calendar', 'calendar');
        calendar.setPreset(this.preset);
      }
    })

  }

  dayClicked(sel:any, day:any, el:any, router: Router)
  {
    var d = new Date(sel[0]);
    let dateString = formatDate(d, 'yyyyMMdd', 'en');
    router.navigate(['/grain/dailyLoads',dateString]);
  }

}
