import {Component, Inject} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { AuthService } from 'src/app/auth/auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import {NavigationExtras} from "@angular/router";
import {AppSettings} from "../../shared/shared.module";
import {interval, Subscription} from "rxjs";

@Component({
  selector: 'app-usercreate',
  templateUrl: './usercreate.component.html',
  styleUrl: './usercreate.component.css'
})
export class UsercreateComponent {
  userTypes = [
    {id: "ADMINISTRATOR", name: "ADMINISTRATOR"},
    {id: "STANDARD_USER", name: "STANDARD_USER"},
    
 ];
  
  userType : any = "";
  userName : any = "";
  userEmail : any = "";
  userPassword : any = "";
  selectedUserType : any = "STANDARD_USER";

  constructor(private http: HttpClient,  @Inject('BASE_URL')private baseUrl: string, public auth:AuthService, public shared:SharedModule, private _router:Router,private route: ActivatedRoute) {
    this.userType = auth.currentUserValue?.usertype;
  }


  validate() {
    let errorMessage:string = "";
   if (this.userName == "")
     errorMessage = "User Name can not be blank";
    else
    if (this.userEmail == "")
      errorMessage = "User Email can not be blank";
    else
    if (this.userPassword == "")
      errorMessage = "User Password can not be blank";
    else
    if (this.selectedUserType == "")
      errorMessage = "User Type MUST be selected, and can not be blank";

    return errorMessage;
  }

  saveFormData()
  {
    let errorString = this.validate();
    let bAllDataSaved = false;
    if (errorString.length == 0) {
      let formData:FormData = new FormData();
      formData.set("userName",this.userName);
      formData.set("userEmail",this.userEmail);
      formData.set("userType",this.selectedUserType);
      formData.set("userPassword",this.userPassword);
      this.http
      .post(this.baseUrl + 'Authentication/SaveNewUser', formData)
      .subscribe({
        next: (response) => this.saveFinished(),
        error: (error) => this.shared.metroAlert("There was an error and the user has not been saved.<br/><br/>" + error.message, "Failed", true),
      });



    }
    else{
      this.shared.metroAlert(errorString, "The dialog contains errors", true);
    }
  }

  saveFinished()
  {
    this.shared.metroAlert("System settings saved.","Save Data");
    this._router.navigate(['/'])
  }

}
