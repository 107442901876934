import {Component, Inject,  ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {AppSettings, SharedModule} from "../../shared/shared.module";
declare var Metro:any;

@Component({
  selector: 'app-auction-create',
  templateUrl: './auction-create.component.html',
  styleUrls: ['./auction-create.component.css']
})
export class AuctionCreateComponent {
  @ViewChild('lotTypeSelect') lotTypeSelect!: ElementRef;
  @ViewChild('noLots') noLots!: ElementRef;
  @ViewChild('lotSize') lotSize!: ElementRef;
  @ViewChild('totalSize') totalSize!: ElementRef;
  @ViewChild('auction') auction!: ElementRef;
  @ViewChild('auctionReserve') auctionReserve!: ElementRef;
  @ViewChild('auctionBuyItNow') auctionBuyItNow!: ElementRef;
  @ViewChild('auctionDisplayName') auctionDisplayName!: ElementRef;
  @ViewChild('auctionDisplayTonnage') auctionDisplayTonnage!: ElementRef;
  @ViewChild('auctionKeepListing') auctionKeepListing!: ElementRef;
  @ViewChild('auctionShowReserve') auctionShowReserve!: ElementRef;

  lotType: number = 1;
  availableSpace: any;
  calcSpace: number = 0;
  hasReserve: string = "1";
  buyItNow: string = "1";
  auctionDates:any[] = [];
  dataLoaded:boolean = true;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string,private router: Router,public sharedFunctions: SharedModule) {
    this.http.get<any>(this.baseUrl + 'Grain/Auction/GetAuctionInfo').subscribe({
      next: (result: any) => {
        this.availableSpace = parseInt(result.memberAvailableSpace.toString());
        this.auctionDates = result.AuctionDates;
        this.dataLoaded = false;
      },
      error: (error: any) => {

      }
    });
  }

  typeChanged() {
    this.lotType = this.lotTypeSelect.nativeElement.value;
    this.calcValue();
  }

  calcValue() {
    if (this.lotType != 2) {
      let noOfLots = this.noLots.nativeElement.value;
      let lotSize = this.lotSize.nativeElement.value;
      if (!(isNaN(noOfLots) && isNaN(lotSize))) {
        this.calcSpace = noOfLots * lotSize;
      }
    }else {
      this.calcSpace = this.totalSize.nativeElement.value;
    }
  }

  cancelForm() {
    this.router.navigate(['grain', 'auction', 'sell']);
  }

  validate(formData: FormData) {
    let errorMessage:string = "";
    if ((parseInt(formData.get("auctionTotalSpace")?.toString() ?? "0") ?? 0) == 0){
      if (this.lotType == 1) {
        errorMessage = "You must enter both a number of lots, and a lot size.";
      }else{
        errorMessage = "You Must Enter the Total Space you with to auction.";
      }
    } else if ((parseInt(formData.get("auctionReserve")?.toString() ?? "0") ?? 0) == 0 && this.hasReserve == "1"){
      errorMessage = "You Must Enter A Reserve Price";

    } else if ((parseInt(formData.get("auctionBuyItNow")?.toString() ?? "0") ?? 0) == 0 && this.buyItNow == "1"){
      errorMessage = "You Must Enter A BUY IT NOW Price";

    }

    else if (((parseInt(formData.get("auctionReserve")?.toString() ?? "0") ?? 0) < AppSettings.MIN_RESERVE_PRICE) && this.hasReserve == "1"){
      errorMessage = "You must enter a reserve greater than the site minimum(" + AppSettings.MIN_RESERVE_PRICE + ")";
    } else if (((parseInt(formData.get("auctionReserve")?.toString() ?? "0") ?? 0) > AppSettings.MAX_RESERVE_PRICE) && this.hasReserve == "1"){
      errorMessage = "Your reserve is greater than the site maximum(" + AppSettings.MAX_RESERVE_PRICE + ")" ;
    } else if (((parseInt(formData.get("auctionBuyItNow")?.toString() ?? "0") ?? 0) < AppSettings.MIN_BUY_IT_NOW_PRICE) && this.buyItNow == "1"){
      errorMessage = "You must enter a BUY IT NOW PRICE greater than site minimum(" + AppSettings.MIN_BUY_IT_NOW_PRICE + ")";
    } else if (((parseInt(formData.get("auctionBuyItNow")?.toString() ?? "0") ?? 0) > AppSettings.MAX_BUY_IT_NOW_PRICE) && this.buyItNow == "1"){
      errorMessage = "You must enter a BUY IT NOW PRICE less than site maximum(" + AppSettings.MAX_BUY_IT_NOW_PRICE + ")";
    }
    else if ((parseInt(formData.get("auctionTotalSpace")?.toString() ?? "0") ?? 0) > AppSettings.MAX_LOT_SIZE && this.lotType != 1){
      errorMessage = "Your auction amount is too high, please lower to below site maxmimum(" + AppSettings.MAX_LOT_SIZE + ")";
    }
    else if ((parseInt(formData.get("auctionTotalSpace")?.toString() ?? "0") ?? 0) < AppSettings.MIN_LOT_SIZE && this.lotType != 1){
      errorMessage = "Your auction amount is too low, please increase the amount to auction(" + AppSettings.MIN_LOT_SIZE + ")";
    }



    else if ((parseInt(formData.get("auctionBuyItNow")?.toString() ?? "0") ?? 0) == 0 && this.buyItNow == "1"){
      errorMessage = "You Must Enter A Buy It Now Price";
    } else if ((parseInt(formData.get("auctionTotalSpace")?.toString() ?? "0") ?? 0) > this.availableSpace){
      errorMessage = "You cannot auction more space that you have available. You have " + this.availableSpace + " available to auction.";
    } else if (!$("#acceptTerms-sell").is(":checked")){
      errorMessage = "You must accept the terms and conditions before continuing.";
    }
    return errorMessage;
  }

  saveRecord() {
    let formData:FormData = new FormData();
    formData.set("auctionID",this.auction.nativeElement.value);
    formData.set("auctionTotalSpace",this.lotType == 1 ? this.calcSpace.toString() : this.totalSize.nativeElement.value  != '' ? this.totalSize.nativeElement.value : 0);
    formData.set("auctionIsInLots",(this.lotType == 1 ? "true" : "false"));
    formData.set("auctionLotSize", this.lotSize.nativeElement.value != '' ? this.lotSize.nativeElement.value : 0);
    formData.set("auctionTotalLots",this.noLots.nativeElement.value != '' ? this.noLots.nativeElement.value : 0);
    formData.set("auctionReserve",this.auctionReserve.nativeElement.value != '' ? this.auctionReserve.nativeElement.value : 0);
    formData.set("auctionBuyItNow",this.auctionBuyItNow.nativeElement.value != '' ? this.auctionBuyItNow.nativeElement.value : 0);
    formData.set("auctionDisplayName", this.auctionDisplayName.nativeElement.value);
    formData.set("auctionDisplayTonnage", "true");
    formData.set("auctionKeepListing", this.auctionKeepListing.nativeElement.value);
    formData.set("auctionShowReserve", this.auctionShowReserve.nativeElement.value);
    formData.set("auctionHasReserve",this.hasReserve);

    let errorMessage = "";


    let errorString:string = this.validate(formData);
    if (errorString.length == 0) {
      this.http
        .post(this.baseUrl + 'Grain/Auction/SaveAuctionItem', formData)
        .subscribe({
          next: (response) =>
          {
            this.sharedFunctions.metroAlert("Your changes have been saved","Success",false);
            this.cancelForm();
          },
          error: (error) => this.sharedFunctions.metroAlert("There was an error and your changes have not been saved<br/><br/>" + error.message, "Failed", true),
        });

      this.hasReserve = "1";
      Metro.dialog.close('#modalNewBid');
    }else{
      this.sharedFunctions.metroAlert(errorString, "Form Not Valid", true);
    }
  }
}
