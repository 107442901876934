import {Component, Inject, OnInit, NgIterable, ViewChild, ElementRef } from '@angular/core';
import {ActivatedRoute, ParamMap} from "@angular/router";
import {HttpClient, HttpParams} from "@angular/common/http";
import { AuthService } from 'src/app/auth/auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';
//import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-detail-complete',
  templateUrl: './detail-complete.component.html',
  styleUrl: './detail-complete.component.css'
})
export class DetailCompleteComponent {

  dataLoaded = false;
  wagonData : any;
  reasons : any;
  currentreason : any;
  public order:string | null = "";
  arrivaldate : any;
  departuredate : any;
  arrivaltime : any;
  departuretime : any;
  comment : any;

  parrivaldate : any;
  pdeparturedate : any;
  parrivaltime : any;
  pdeparturetime : any;
  userType : any = "";

  returnedQty : number = 0;

  private sm : SharedModule;
  //private dp : DatePipe;

  constructor (private route: ActivatedRoute,private http: HttpClient, @Inject('BASE_URL')private baseUrl:string, public shared:SharedModule, private _router : Router
  
  ,auth:AuthService)
  {
    this.sm = shared;
   // this.dp = datePipe;
    this.userType = auth.currentUserValue?.usertype;
  }

  ngOnInit() {

    let counter : number = 0;
    
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.order = params.get('order');
      if (this.order) {
         counter ++;
         this.dataLoaded = counter == 5;
      }
    });

   
    let params = new HttpParams().set("trainID",this.order != null ? this.order : "")
    this.http.get(this.baseUrl + 'Rail/GetWagonData', {params:params} ).subscribe(
      (data:any) => {
        this.wagonData = data; 
        counter++;
        this.dataLoaded = counter == 5});

    
    this.http.get(this.baseUrl + 'Rail/GetReasons').subscribe(
          (data:any) => {
            this.reasons = data; 
            counter++;
            this.dataLoaded = counter == 5});
    
    this.http.get(this.baseUrl + 'Rail/GetTrainHeaderComplete', {params:params}).subscribe(
              (data:any) => {
                this.arrivaldate =  data[0].arrivalDate;
                this.arrivaltime = data[0].arrivalTime;
                this.departuredate =  data[0].departureDate;
                this.departuretime = data[0].departureTime;
                this.returnedQty = data[0].returnValue;
                counter++;
                this.dataLoaded = counter == 5});

    this.http.get(this.baseUrl + 'Rail/GetTrainComment', {params:params}).subscribe(
                  (data:any) => {
                    this.comment = data[0].comment;
                    counter++;
                    this.dataLoaded = counter == 5});

  }


  public saveFormData()
  {
       
    
  }

  public saveWagonData(seq : string, value : string)
  {
    
  }

  saveFinished()
  {
    
  }
    
  onChange(id : any) {
    if (id.reason != '')
    this.saveWagonData(id.seq, id.reason);
}

 

}
