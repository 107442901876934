<ul class="h-menu border bd-light" data-role="ripple" data-ripple-target="li">
    <li><a [routerLink]="['/']" [routerLinkActive]="['active']"><span class="mif-home icon"></span>Home</a></li>    
      
</ul>
<h1>Active PLC Connections</h1>

<ng-container *ngIf="!dataLoaded">
    <div id="loading" style="width:100%; text-align: center"><br/><br/><small>Please Wait, we're getting your
        data...</small><br/><br/><img src="assets/images/spinner.svg"></div>
    </ng-container>
    
    <ng-container *ngIf="dataLoaded">
      <div class="row">
        <div class="cell-2">
            <label><B>Milltower PLC</B></label>
        </div>
        <div class="cell-2">
            <label>{{Milltower}}</label>
        </div>
       </div>

       <div class="row">
        <div class="cell-2">
            <label><B>Flaker PLC</B></label>
        </div>
        <div class="cell-2">
            <label>{{Flaker}}</label>
        </div>
       </div>

       <div class="row">
        <div class="cell-2">
            <label><B>Pelleting PLC</B></label>
        </div>
        <div class="cell-2">
            <label>{{Pellet}}</label>
        </div>
       </div>

       <div class="row">
        <div class="cell-2">
            <label><B>TH White Flour</B></label>
        </div>
        <div class="cell-2">
            <label>{{THWFlour}}</label>
        </div>
       </div>

       <div class="row">
        <div class="cell-2">
            <label><B>TH White Packing</B></label>
        </div>
        <div class="cell-2">
            <label>{{THWPacking}}</label>
        </div>
       </div>

       <div class="row">
        <div class="cell-2">
            <label><B>Umbra Line 1</B></label>
        </div>
        <div class="cell-2">
            <label>{{UmbraLine1}}</label>
        </div>
       </div>
        
       <div class="row">
        <div class="cell-2">
            <label><B>Umbra Line 2</B></label>
        </div>
        <div class="cell-2">
            <label>{{UmbraLine2}}</label>
        </div>
       </div>

       <div class="row">
        <div class="cell-2">
            <label><B>Wrapper (Umbra)</B></label>
        </div>
        <div class="cell-2">
            <label>{{Wrapper}}</label>
        </div>
       </div>
        
    </ng-container>