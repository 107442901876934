import {Component, Inject, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { AuthService } from 'src/app/auth/auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';
declare var $:any;

@Component({
  selector: 'app-sacklabel',
  templateUrl: './sacklabel.component.html',
  styleUrl: './sacklabel.component.css'
})
export class SacklabelComponent implements OnInit  {
  private sm : SharedModule;
  userType : any = "";
  
  dataLoaded = false;
 
  woReference : string = '';
  sackwoReference : string = '';
  
  retMessage : string = '';

  isError = false;
  errorMessage: string = '';
  productCode: string = '';
  productDescription : string = '';
  bagStart: string = '';
  baglabels : string = '';
  loading = false;
  totalSoFar = 0;
  //"productionDate": "2024-10-09T08:18:13.613Z",
  //"expiryDate": "2024-10-09T08:18:13.613Z",
  netWeight: string = "0";
  bagsOnPallet : string = "0";
  navaraID : string = "0";
  gS1: string = "";
  canContinue = false;
 
  selectedLine : any = null;
  sackselectedLine : any = null;
  sackLines = [
    {id: '1', text: 'Sack Line 1'},
    {id: '2', text: 'Sack Line 2'},
   ];


  constructor(private http: HttpClient,  @Inject('BASE_URL')private baseUrl: string, public auth:AuthService, public shared:SharedModule, private _router:Router) {
    this.sm = shared;
    this.userType = auth.currentUserValue?.usertype;
  }
 
  ngOnInit(){
    let counter : number = 0;
    this.dataLoaded = true;
    
  }


  public submitFormData()
  {
    let formData:FormData = new FormData();
    this.canContinue = false;
    this.loading = true;
    this.http.post(this.baseUrl + 'Navara/KelgraySackLabel',
      {
      "lineID" : this.selectedLine,
      "woNumber" : this.woReference
      }
      ).subscribe(
        (data:any) => {
          this.retMessage = data[0]["message"]; 
          this.productDescription = data[0]["product"];
          this.bagStart = data[0]["bagstart"];
          this.baglabels = data[0]["baglabels"];
           this.canContinue = true;
         });
        
    this.loading = false;
    //this.saveFinished();
    
  }

  public submitSackCount()
  {
    let formData:FormData = new FormData();
    this.canContinue = false;
    this.loading = true;
    this.http.post(this.baseUrl + 'Navara/KelgraySackProgress',
      {
      "lineID" : this.sackselectedLine,
      "woNumber" : this.sackwoReference,
      "iTotalSoFar" : this.totalSoFar
      }
      ).subscribe(
        (data:any) => {
          
           //this.canContinue = true;
         });
        
    this.loading = false;
    //this.saveFinished();
    
  }

  saveFinished()
  {
    this.sm.metroAlert("Save succesful for : " + this.woReference ,"Title");
    this._router.navigate(['/milling/kelgrey/'])
  }
}
