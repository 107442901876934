import {Component, Input} from '@angular/core';
import {MenuItem} from '../../menu.service';
@Component({
  selector: 'app-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.css']
})
export class SubMenuComponent {
  @Input() roleType: string = "ripple";
  @Input() menuData: MenuItem[] | undefined;
  constructor() {}

}
