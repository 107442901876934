import {Component, Inject} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {NavigationExtras, Router} from "@angular/router";
import {AppSettings, SharedModule} from "../../shared/shared.module";
import {interval, Subscription} from "rxjs";

@Component({
  selector: 'app-auction-buy',
  templateUrl: './auction-buy.component.html',
  styleUrls: ['./auction-buy.component.css']
})


export class AuctionBuyComponent {
  private updateSubscription: Subscription = new Subscription;
  memberInfo: any = {};
  memberAddress: any;
  userInfo: any;
  openAuctions: any = {};
  auctionDates: any = {};
  hasOutstandingSell:boolean = false;
  hasOutstandingBuy:boolean = false;
  loading:boolean = true;

  auctionIDForSave : any = null;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string,private router: Router,public sharedFunctions: SharedModule) {
    this.updateSubscription = interval(AppSettings.STANDARD_PAGE_REFRESH_MS).subscribe(
      (val) => { this.ngOnInit()});

  }
  ngOnInit(){
    this.http.get<any>(this.baseUrl + 'Grain/Auction/GetBuyerAuctionInfo').subscribe({
      next: (result: any) => {
        this.memberInfo = result["memberInfo"] ;
        this.memberAddress = result["memberAddress"] ;
        this.userInfo = result["auctionPrivileges"];
        this.openAuctions = result["openAuctions"];
        this.auctionDates = result["AuctionDates"];

        let outstanding = result["outstandingAuctionRequests"] ;
        if (this.userInfo.auctionCanBuy == 'N') {
          for (const request of outstanding) {
            if (request.requestSell == 'Y') {
              this.hasOutstandingSell = true;
            }
            if (request.requestBuy == 'Y') {
              this.hasOutstandingBuy = true;
            }
          }
        }

        this.loading = false;

      },
      error: (error: any) => {
        console.error(error);
        this.loading = false;
      }
    });
    // this.reload();
  }

  requestAccess() {
    let formData:FormData = new FormData();
    formData.append("requestAccess","true");
    formData.append("requestSelling","false");
    formData.append("requestBuying","true");

    this.http
      .post(this.baseUrl + 'Grain/Auction/RequestAuctionAccess', formData)
      .subscribe({
        next: (response) => this.completeRequest(),
        error: (error) => this.sharedFunctions.metroAlert("There was an error and your changes have not been saved<br/><br/>" + error.message, "Failed", true),
      });
  }

  completeRequest(){
    this.sharedFunctions.metroAlert("Thank you. Your request has been received, and you will be notified when access has been granted.", "Received", false);
    this.ngOnInit();
  }

  agreeToTerms() {
    this.http
      .put(this.baseUrl + 'Grain/Auction/AcceptTerms',"")
      .subscribe({
        next: (response) => this.ngOnInit(),
        error: (error) => this.sharedFunctions.metroAlert("There was an error and your changes have not been saved<br/><br/>" + error.message, "Failed", true),
      });
  }

  openBidWindow(item: any) {
    const jsonString = JSON.stringify(item);
    localStorage.setItem('bidItem', jsonString);
    this.router.navigate(["/grain/auction/buy/bid/"]);
  }

  openBuyItNow(item: any) {
    this.auctionIDForSave = item;
    let ret = this.sharedFunctions.metroYesNo("Buy It Now", "Are you sure you want to Buy It Now for this auction", this, this.saveBin);
  }

  saveBin(parent : any){
      if (parent != null)
      {
        let formData:FormData = new FormData();
        formData.append("auctionItemID",parent.auctionIDForSave);
      
        parent.http
          .post(parent.baseUrl + 'Grain/Auction/SaveBINRequest', formData)
          .subscribe({
          next: (response: any) => parent.completeBIN(),
          error: (error: { message: string; }) => parent.sharedFunctions.metroAlert("There was an error and your changes have not been saved<br/><br/>" + error.message, "Failed", true),
        });
        
      }
    }



    completeBIN(){
      this.sharedFunctions.metroAlert("Thank you. Your buy it now request has been accepted.", "Accepted", false);
      this.ngOnInit();

  }
}
