<div id="loading" style="width:100%; text-align: center"><br/><br/><small>Please Wait, we're getting your data...</small><br/><br/><img src="assets/images/spinner.svg"></div>
<table class="table mt-4 subcompact " 
         data-pagination="false" data-show-search="false" data-show-rows-steps="false" data-show-search="false"
         data-rows="-1" data-table-info-title="" showTableInfo="false" >
    <div class="row">
      <div class="cell-5"><b>{{mAddress.membertradingname }}</b></div> <div class="cell-5" style="text-align: Right"><B>Summary for {{year}}</B></div>
      </div>
    <div class="row">
      <div class="cell-5">{{mAddress.memaddressadd1 }}</div>
    </div> 
    <div class="row">
      <div class="cell-5">{{mAddress.memaddressadd2 }}</div>
    </div> 
    
    <div class="row">
      <div class="cell-5">{{mAddress.memaddressadd4 }}</div>
    </div> 
    <div class="row">
      <div class="cell-5">{{mAddress.memaddresstown }}</div>
    </div> 
    <div class="row">
      <div class="cell-5">{{mAddress.memaddresspcode }}</div>
    </div> 
    <div class="row">
    <br><br>
    </div>   
      
  </table>

<div *ngFor="let grade of grades | keyvalue; let i = index" ng-cloak  class="ng-cloak">
  <!--<div *ngIf="i==0" ><img src='/assets/images/print.png' onclick="window.print();return false;"></div>-->

  
  
  
  
  
  
  <b>{{grade.key}}</b>
  <table class="table table-border mt-4 subcompact row-hover row-border " [attr.id]="'summary_table_'+i"
         data-pagination="false" data-show-search="false" data-show-rows-steps="false" data-show-search="false"
         data-rows="-1" data-table-info-title="1 Loads" showTableInfo="false" >
    <thead>
    <tr>
      <th>Movement No</th>
      <th>Ticket</th>
      <th>Ticket Date</th>
      <th>Weighbridge Ref 1</th>
      <th>Weighbridge Ref 2</th>
      <th>Item No</th>
      <th>Moist %</th>
      <th>Admix / Scm %</th>
      <th>Spec Wt</th>
      <th>P/O/N</th>
      <th>Hag/Bru/Germ</th>
      <th>Ret</th>
      <th>Ergot Present</th>
      <th align="right">Delivered Weight</th>
      <th align="right">Admix / Scm Adj</th>
      <th align="right">Moist Adj</th>
      <th align="right">Throughput Adj</th>
      <th align="right">Stored Weight</th>
    </tr>
    </thead>
    <tbody>
      <tr *ngFor="let record of grade.value">
        <!--
        <td><a [routerLink]="['/show-detailed-load',record.trnReference]">{{record.trnGrainPassportNumber}}</a></td>
        <td><a [routerLink]="['/show-detailed-load',record.trnReference]">{{record.trnDelOrderNumber}}</a></td>
        <td><a [routerLink]="['/show-detailed-load',record.trnReference]">{{record.trnTicketNumber}}</a></td>
        <td><a [routerLink]="['/show-detailed-load',record.trnReference]">{{record.trnVarietyDesc}}</a></td>
        <td><a [routerLink]="['/show-detailed-load',record.trnReference]">{{record.trnDtNettWeight}}</a></td>
        <td><a [routerLink]="['/show-detailed-load',record.trnReference]">{{record.trnVehicleReg}}</a></td>
        <td><a [routerLink]="['/show-detailed-load',record.trnReference]">{{sharedFunctions.ConvertWeightToDisplay(record.trnNettWeight)}}</a></td>
        <td><a [routerLink]="['/show-detailed-load',record.trnReference]">{{sharedFunctions.ConvertWeightToDisplay(record.trnMemberStorageWeight)}}</a></td>
        -->

        <td><a [routerLink]="['/grain/show-detailed-load',record.trnReference]">{{record.trnGrainPassportNumber}}</a></td>
        <td><a [routerLink]="['/grain/show-detailed-load',record.trnReference]">{{record.trnCalculatedValue3}}</a></td>
        <td><a [routerLink]="['/grain/show-detailed-load',record.trnReference]">{{record.trnDtNettWeight | date :'dd/MM/yyyy'}} </a></td>
        <td><a [routerLink]="['/grain/show-detailed-load',record.trnReference]">{{record.trnCalculatedValue4}}</a></td>
        <td><a [routerLink]="['/grain/show-detailed-load',record.trnReference]">{{record.trnCalculatedValue5}}</a></td>
        <td><a [routerLink]="['/grain/show-detailed-load',record.trnReference]">{{record.trnCalculatedValue1}}</a></td>
        <td><a [routerLink]="['/grain/show-detailed-load',record.trnReference]">{{record.trnMoisture.toFixed(2)}}</a></td>
        <td><a [routerLink]="['/grain/show-detailed-load',record.trnReference]">{{record.trnAdmix.toFixed(2)}}</a></td>
        <td><a [routerLink]="['/grain/show-detailed-load',record.trnReference]">{{record.trnSPW.toFixed(2)}}</a></td>
        <td><a [routerLink]="['/grain/show-detailed-load',record.trnReference]">{{record.trnPON.toFixed(2)}}</a></td>
        <td><a [routerLink]="['/grain/show-detailed-load',record.trnReference]">{{record.trnGerm.toFixed(2)}}</a></td>
        <td><a [routerLink]="['/grain/show-detailed-load',record.trnReference]">{{record.trnRET.toFixed(2)}}</a></td>
        <td><a [routerLink]="['/grain/show-detailed-load',record.trnReference]">{{record.trnErgot.toFixed(2)}}</a></td>
        <td><a [routerLink]="['/grain/show-detailed-load',record.trnReference]">{{this.sharedFunctions.ConvertWeightToDisplay(record.trnNettWeight)}}</a></td>
        <td><a [routerLink]="['/grain/show-detailed-load',record.trnReference]">{{this.sharedFunctions.ConvertWeightToDisplay(record.trnSCREENDED)}}</a></td>
        <td><a [routerLink]="['/grain/show-detailed-load',record.trnReference]">{{this.sharedFunctions.ConvertWeightToDisplay(record.trnMOISTDED)}}</a></td>
        <td><a [routerLink]="['/grain/show-detailed-load',record.trnReference]">{{this.sharedFunctions.ConvertWeightToDisplay(record.trnSTORELOSS)}}</a></td>
        <td><a [routerLink]="['/grain/show-detailed-load',record.trnReference]">{{this.sharedFunctions.ConvertWeightToDisplay(record.trnMemberStorageWeight)}}</a></td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td><b>{{sharedFunctions.ConvertWeightToDisplay(getTotal(grade.value,'trnNettWeight'))}}</b></td>
        <td></td>
        <td></td>
        <td></td>
        <td><b>{{sharedFunctions.ConvertWeightToDisplay(getTotal(grade.value,'trnMemberStorageWeight'))}}</b></td>
        </tr>
    </tbody>
  </table>
  <div class="table-bottom"><div class="table-info">{{grade.value.length}} Loads</div><div class="table-pagination"><ul class="pagination"></ul></div></div>
</div>
