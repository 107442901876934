<app-no-permission  *ngIf="userType == 'STANDARD_USER'"></app-no-permission>
<ng-container *ngIf="!dataLoaded">
    <div id="loading" style="width:100%; text-align: center"><br/><br/><small>Please Wait, we're getting your
        data...</small><br/><br/><img src="assets/images/spinner.svg"></div>
    </ng-container>
    
    <ng-container *ngIf="dataLoaded">
<ng-container *ngIf="userType == 'ADMINISTRATOR'">
   
    <ul class="h-menu border bd-light" data-role="ripple" data-ripple-target="li">
        <li><a [routerLink]="['/milling/kelgrey']" [routerLinkActive]="['active']"><span class="mif-home icon"></span>Kelgrey Test Page</a></li>
    </ul>

<div>

   
        
    <h1>Sack Label</h1>

    <form>
        <div class="row">
    <div class="cell-5">
          <label>Sack Line</label>
          <select data-role="select" id="Line"   [(ngModel)]="selectedLine" [ngModelOptions]="{standalone: true}">
              <option *ngFor="let c of sackLines" [ngValue]="c.id">{{c.text}}</option>
          </select>
    </div>
    </div>
    <div class="row">
        <div class="cell-5">
            <label>Works Order </label>
            <input id="woReference"  [(ngModel)]="woReference"  [ngModelOptions]="{standalone: true}" class="myInputs" autocomplete="off" data-role="input" style="text-transform:uppercase">
        </div>
    </div>
    <div class="row">
        <div class="cell">
            <button class="button primary" (click)="submitFormData()">Process Label</button>
        </div>
    </div>
    </form>

    <form>
        <div class="row">
    <div class="cell-5">
          <label>Sack Line Progress</label>
          <select data-role="select" id="Line"   [(ngModel)]="sackselectedLine" [ngModelOptions]="{standalone: true}">
              <option *ngFor="let c of sackLines" [ngValue]="c.id">{{c.text}}</option>
          </select>
    </div>
    </div>
    <div class="row">
        <div class="cell-5">
            <label>Works Order Progress</label>
            <input id="sackwoReference"  [(ngModel)]="sackwoReference"  [ngModelOptions]="{standalone: true}" class="myInputs" autocomplete="off" data-role="input" style="text-transform:uppercase">
        </div>
    </div>
    <div class="row">
        <div class="cell-5">
            <label>Sacks So Far</label>
            <input id="totalSoFar"  [(ngModel)]="totalSoFar"  [ngModelOptions]="{standalone: true}" class="metro-input" autocomplete="off" type = "number" style="text-transform:uppercase">
        </div>
    </div>
    <div class="row">
        <div class="cell">
            <button class="button primary" (click)="submitSackCount()">Process Progess Msg</button>
        </div>
    </div>
    </form>


    <ng-container *ngIf="canContinue">
        <div class="row">
            <div class="cell-8">
                <label>System Message</label>
                <input id="message"  [(ngModel)]="retMessage"  [ngModelOptions]="{standalone: true}" class="myInputs" autocomplete="off" data-role="input" style="text-transform:uppercase">
            </div>
        </div>
    
    <div class="row">
        <div class="cell-5">
            <label>Product Description</label>
            <input id="productDescription"  [(ngModel)]="productDescription"  [ngModelOptions]="{standalone: true}" class="myInputs" autocomplete="off" data-role="input" style="text-transform:uppercase">
        </div>
    </div>
    <div class="row">
        <div class="cell-5">
            <label>Bag Start</label>
            <input id="bagStart"  [(ngModel)]="bagStart"  [ngModelOptions]="{standalone: true}" class="myInputs" autocomplete="off" data-role="input" style="text-transform:uppercase">
        </div>
    </div>
    <div class="row">
        <div class="cell-5">
            <label>No of Bags</label>
            <input id="baglabels"  [(ngModel)]="baglabels"  [ngModelOptions]="{standalone: true}" class="myInputs" autocomplete="off" data-role="input" style="text-transform:uppercase">
        </div>
    </div>
    
    </ng-container>
</div>
</ng-container>
</ng-container>

