import {Component, ElementRef, Inject, Renderer2} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import { formatDate} from '@angular/common';
import { SharedModule, simpleLoadType } from 'src/app/shared/shared.module';

//Make the javascript library available internal
declare var Metro:any;
declare var $:any;
@Component({
  selector: 'app-todays-loads',
  templateUrl: './todays-loads.component.html',
  styleUrls: ['./todays-loads.component.css']
})
export class TodaysLoadsComponent {
  private _listeners: { destroy: () => void }[] = [];
  
  selectedDate:Date = new Date();

  dateLabel: string = "";


  constructor(private http: HttpClient,  @Inject('BASE_URL')private baseUrl: string, public route: ActivatedRoute, private _el: ElementRef,private _router: Router,
              private _renderer: Renderer2, public sharedFunctions: SharedModule) {

  }

  static ParamToDate(value:string): Date | null
  {
    let ret:Date |  null = null;
    if (value.length == 8) {
      var pattern = /(\d{4})(\d{2})(\d{2})/;
      ret = new Date(value.replace(pattern, '$1-$2-$3'));
    }
    return ret;
  }
  ngOnInit(){
    this.route.paramMap.subscribe(param => {
      const selectedDate = param.get('selectedDate')
        if (selectedDate) {
          let formattedDate:Date | null = TodaysLoadsComponent.ParamToDate(selectedDate);
          // @ts-ignore
          this.selectedDate = formattedDate;
        }else{
          this.selectedDate = new Date();
        }
        this.loadTable();
    });

  }

  public loadTable(){
    if (this.selectedDate.setHours(0) == new Date().setHours(0)){
      this.dateLabel = "Todays Loads:";
    }else{
      this.dateLabel = "Loads for:";
    }
    let queryParams = new HttpParams()
      .set("movementYear",  formatDate(this.selectedDate, 'yyyy-MM-dd', 'en'));
    
    this.http.get<simpleLoadType[]>(this.baseUrl + 'Grain/GetSimpleLoads',{params: queryParams}).subscribe({
        next: (result: simpleLoadType[]) => {
  
          
          var table = Metro.getPlugin('#simpleloads', 'table');
          table.clear();
          let thisObj = this;
          result.forEach(function (item, index) {
  
            var row = [
              '<a class="dynamicClick" href="/grain/show-detailed-load/'+encodeURIComponent(item.trnReference)+'">'+thisObj.sharedFunctions.Escape(item.trnGrainPassportNumber)+'</a>',
              '<a class="dynamicClick" href="/grain/show-detailed-load/'+encodeURIComponent(item.trnReference)+'">'+thisObj.sharedFunctions.Escape(item.trnDelOrderNumber)+'</a>',
              '<a class="dynamicClick" href="/grain/show-detailed-load/'+encodeURIComponent(item.trnReference)+'">'+thisObj.sharedFunctions.Escape(item.trnVarietyDesc)+'</a>',
              '<a class="dynamicClick" href="/grain/show-detailed-load/'+encodeURIComponent(item.trnReference)+'">'+thisObj.sharedFunctions.Escape(item.trnVehicleReg)+'</a>',
              '<a class="dynamicClick" href="/grain/show-detailed-load/'+encodeURIComponent(item.trnReference)+'">'+thisObj.sharedFunctions.Escape(thisObj.sharedFunctions.ConvertWeightToDisplay(item.trnNettWeight))+'</a>',
              '<a class="dynamicClick" href="/grain/show-detailed-load/'+encodeURIComponent(item.trnReference)+'">'+thisObj.sharedFunctions.Escape(thisObj.sharedFunctions.ConvertWeightToDisplay(item.trnMemberStorageWeight))+'</a>'
            ];
  
            table.addItem(row, false);
  
          });
          table.draw();
          const links = this._el.nativeElement.querySelectorAll('.dynamicClick');
  
          links.forEach(function(link:any){
            thisObj._renderer.setAttribute(link, 'routerLink', link?.getAttribute('href'));
            const destroyListener = thisObj._renderer.listen(link, 'click',
              (_event) => {
                _event.preventDefault();
                _event.stopPropagation();
                thisObj._router.navigateByUrl(link?.getAttribute('href'));
              });
            thisObj._listeners.push({ destroy: destroyListener });
          });
        },
        error: (error: any) => {
          console.error(error);
        }
      });
  
    
  }


}
