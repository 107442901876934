import {Component, Inject} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {TodaysLoadsComponent} from "../home/components/todays-loads/todays-loads.component";
import { SharedModule } from 'src/app/shared/shared.module';

import {
  Dictionary,
  DocumentModel,
  SampleDetailModel,
  SummaryDetailsModel,
} from "src/app/shared/shared.module";
declare var Metro:any;

@Component({
  selector: 'app-detailed-load',
  templateUrl: './detailed-load.component.html',
  styleUrls: ['./detailed-load.component.css']
})
export class DetailedLoadComponent {

  SummaryDetails:SummaryDetailsModel = {} as SummaryDetailsModel;
  Reference:string|null = null;
  SampleDetails:SampleDetailModel[] = [];

  loss:number = 0;
  lossAsWeight:number = 0;
  deductionAsWeight:number = 0;
  totalDeductionAsCharge:number = 0;
  totalCleaningCharge:number = 0;
  totalHandlingCharge:number = 0;
  totalStandingCharge:number = 0;

  documents:DocumentModel[] = [];
  loaded: boolean = false;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, public route: ActivatedRoute, public router: Router, public sharedFunctions: SharedModule) {

  }

  ngOnInit(){
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.Reference = params.get('id');
      if (this.Reference) {
        let queryParams = new HttpParams()
          .set("reference", this.Reference);

        this.http.get<Dictionary>(this.baseUrl + 'Grain/ShowDetailedLoad', {params: queryParams}).subscribe({
          next: (result: Dictionary) => {
            this.SummaryDetails = result["data"] as SummaryDetailsModel;
            this.SampleDetails = result["sampleData"] as SampleDetailModel[];
            this.loss = result["loss"] as unknown as number;
            this.deductionAsWeight = result["deductionAsWeight"] as unknown as number;
            this.totalDeductionAsCharge = result["totalDeductionAsCharge"] as unknown as number;
            this.totalCleaningCharge = result["totalCleaningCharge"] as unknown as number;
            this.totalHandlingCharge = result["totalHandlingCharge"] as unknown as number;
            this.lossAsWeight = result["lossAsWeight"] as unknown as number;
            this.totalStandingCharge =  result["totalStandingCharge"] as unknown as number;
            this.documents = result["documents"] as DocumentModel[];
            this.loaded = true;
          },
          error: (error: any) => {
            this.sharedFunctions.metroAlert("An unexpected error occurred, please check the console log","Error",true);
            console.error(error);
          }
        });
      }
    });

  }

  linkOpen(docReference:string) {

      let queryParams = new HttpParams()
        .set("ticketReference", docReference);
      // this.http.get<ReportDownload>(this.baseUrl + 'MemberContent/GetTicket', {params: queryParams}).subscribe({
      //   next: (result: ReportDownload) => {
      //     var blob = this.sharedFunctions.base64ToBlob(result.data, result.mimetype);
      //     var url = window.URL.createObjectURL(blob);
      //     window.open(url, "_blank", "toolbar=no, scrollbars=yes, resizable=yes, top=100, left=100,  height=500px");
      //   },
      //   error: (error: any) => {
      //     console.error(error);
      //   }
      // });

    return false;
  }


}




