<div class="grid">
    <div class="row">
        <div class="cell-2">
         <app-main-menu></app-main-menu>
        </div>
        <div class="cell-10">
          <router-outlet name="grain-outlet" >
            <ng-container *ngIf="!grainOutletActivated">
              <div class="cell flex-align-self-end">
                <div class="cell"><h4>Account Details:</h4></div>
                <div class="grid">
                  <div class="row no-gap">
                    <div class="cell-1">
                      <div></div>
                    </div>
                    <div class="cell-4">
                      <div><b>Member : </b></div>
                    </div>
                    <div class="cell-5">
                      <div>{{memberInfo.membertradingname}}</div>
                    </div>
                  </div>
                  <div class="row no-gap">
                    <div class="cell-1">
                      <div></div>
                    </div>
                    <div class="cell-4">
                      <div><b>Membership No: </b></div>
                    </div>
                    <div class="cell-5">
                      <div>{{memberInfo.memberextid}}</div>
                    </div>
                  </div>
                  <div class="row ">
                    <div class="cell-1">
                      <div></div>
                    </div>
                    <div class="cell-4">
                      <div></div>
                    </div>
                    <div class="cell-5">
                      <div></div>
                    </div>
                  </div>
                  <div class="row no-gap">
                    <div class="cell-1">
                      <div></div>
                    </div>
                    <div class="cell-4">
                      <div><b>Address : </b></div>
                    </div>
                    <div class="cell-5">
                      <div>{{memberAddress?.memaddressadd1}}</div>
                    </div>
                  </div>
                  <div class="row no-gap">
                    <div class="cell-1">
                      <div></div>
                    </div>
                    <div class="cell-4">
                      <div></div>
                    </div>
                    <div class="cell-5">
                      <div>{{memberAddress?.memaddressadd2}}</div>
                    </div>
                  </div>
                  <div class="row no-gap">
                    <div class="cell-1">
                      <div></div>
                    </div>
                    <div class="cell-4">
                      <div></div>
                    </div>
                    <div class="cell-5">
                      <div>{{memberAddress?.memaddressadd3}}</div>
                    </div>
                  </div>
                  <div class="row no-gap">
                    <div class="cell-1">
                      <div></div>
                    </div>
                    <div class="cell-4">
                      <div></div>
                    </div>
                    <div class="cell-5">
                      <div>{{memberAddress?.memaddressadd4}}</div>
                    </div>
                  </div>
                  <div class="row no-gap">
                    <div class="cell-1">
                      <div></div>
                    </div>
                    <div class="cell-4">
                      <div></div>
                    </div>
                    <div class="cell-5">
                      <div>{{memberAddress?.memaddresstown}}</div>
                    </div>
                  </div>
                  <div class="row no-gap">
                    <div class="cell-1">
                      <div></div>
                    </div>
                    <div class="cell-4">
                      <div></div>
                    </div>
                    <div class="cell-5">
                      <div>{{memberAddress?.memaddresscounty}}</div>
                    </div>
                  </div>
                  <div class="row no-gap">
                    <div class="cell-1">
                      <div></div>
                    </div>
                    <div class="cell-4">
                      <div></div>
                    </div>
                    <div class="cell-5">
                      <div>{{memberAddress?.memaddresspcode}}</div>
                    </div>
                  </div>
                  <div class="row ">
                    <div class="cell-1">
                      <div></div>
                    </div>
                    <div class="cell-4">
                      <div></div>
                    </div>
                    <div class="cell-5">
                      <div></div>
                    </div>
                  </div>
                  <div class="row no-gap">
                    <div class="cell-1">
                      <div></div>
                    </div>
                    <div class="cell-4">
                      <div><b>Storage Owned : </b></div>
                    </div>
                    <div class="cell-5">
                      <div>{{(memberInfo.memberspace ?? 0).toFixed(2)}}</div>
                    </div>
                  </div>
                  <div class="row no-gap">
                    <div class="cell-1">
                      <div></div>
                    </div>
                    <div class="cell-4">
                      <div><b>Tonnage Permitted for Reallocation: </b></div>
                    </div>
                    <div class="cell-5">
                      <div>{{(memberInfo.memberspace).toFixed(2)}}</div>
                    </div>
                  </div>
                  <div class="row ">
                    <div class="cell-1">
                      <div></div>
                    </div>
                    <div class="cell-4">
                      <div></div>
                    </div>
                    <div class="cell-5">
                      <div></div>
                    </div>
                  </div>
                  
                  <div class="row ">
                    <div class="cell-1">
                      <div></div>
                    </div>
                    <div class="cell-4">
                      <div></div>
                    </div>
                    <div class="cell-5">
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </router-outlet>
        </div>
    </div>
</div>
