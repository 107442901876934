<ul class="h-menu border bd-light" data-role="ripple" data-ripple-target="li">
    <li><a [routerLink]="['/']" [routerLinkActive]="['active']"><span class="mif-home icon"></span>Home</a></li>      
</ul>

<h1>Kiln Process</h1>
<ng-container >
<form>
    <div class="row mb-2">
        <label class="cell-sm-2">Kiln 1 - Treat Abort as Pause</label>
        <div class="cell-sm-10">
            <input id="warehouse" type="checkbox" class="metro-input" value="WIPB1">
        </div>
    </div>

    <div class="row mb-2">
        <label class="cell-sm-2">Kiln 2 - Treat Abort as Pause</label>
        <div class="cell-sm-10">
            <input id="warehouse" type="checkbox" class="metro-input" value="WIPB1">
        </div>
    </div>

</form>
</ng-container>