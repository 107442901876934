import {Component, Inject} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { AuthService } from 'src/app/auth/auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import {NavigationExtras} from "@angular/router";
import {AppSettings} from "../../shared/shared.module";
import {interval, Subscription} from "rxjs";

@Component({
  selector: 'app-no-permission',
  templateUrl: './no-permission.component.html',
  styleUrl: './no-permission.component.css'
})

export class NoPermissionComponent {
  
  
  constructor(private http: HttpClient,  @Inject('BASE_URL')private baseUrl: string, public auth:AuthService, public shared:SharedModule, private _router:Router,private route: ActivatedRoute) {
    
  }
 
}
