import {Component, Inject} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { AuthService } from 'src/app/auth/auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';
declare var $:any;


@Component({
  selector: 'app-milling-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class MillingComponent {
  private sm : SharedModule;

  constructor(private http: HttpClient,  @Inject('BASE_URL')private baseUrl: string, public auth:AuthService, public shared:SharedModule, private _router:Router) {
    this.sm = shared;
  }


  validate(formData: FormData) {
    let errorMessage:string = "";
   if (formData.get("item1")?.toString() == "")
     errorMessage = "Put some data in Dummy !!";

    //if ((parseInt(formData.get("bidAmount")?.toString() ?? "0") ?? 0) <= 0){
    //  errorMessage = "You must enter a positive value for your bid amount.";      
    //} else if ((parseInt(formData.get("bidAmount")?.toString() ?? "0") ?? 0) < AppSettings.MIN_BID_AMOUNT && !this.selectedIsForLots) {
    //  errorMessage = "The bid amount must be greater or equal to " + AppSettings.MIN_BID_AMOUNT;
    //} 
    return errorMessage;
  }


  public saveFormData()
  {
    let formData:FormData = new FormData();
    //var bid = parseInt($('#bidAmount-buy').val()?? "0");
    formData.set("SystemToUse",$('#System').val() != '' ? $('#System').val() : '');
   
    //formData.set("bidTones", $('#bidTones-buy').val() != '' ? $('#bidTones-buy').val() : 0);
    //formData.set("bidLots",$('#bidLots-buy').val() != '' ? $('#bidLots-buy').val() : 0);
    //formData.set("bidLimit",$('#bidLimit-buy').val() != '' ? $('#bidLimit-buy').val() : 0);
    //formData.set("isForLots",this.selectedIsForLots ? "true" : "false");

    let errorString = this.validate(formData);

    if (errorString.length == 0) {
      this.http
        .post(this.baseUrl + 'SaveRecord/SaveSystem', formData)
        .subscribe({
          next: (response) => this.saveFinished(),
          error: (error) => this.sm.metroAlert("There was an error and your changes have not been saved<br/><br/>" + error.message, "Failed", true),
        });
      //this.reload();  

    }else{
      this.sm.metroAlert(errorString, "The dialog contains errors", true);
    }
    
  }
  

  saveFinished()
  {
    this.sm.metroAlert("We have saved the form.","Title");
    this._router.navigate(['/'])
  }
}
