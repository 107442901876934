<li *ngFor="let item of menuData">
  <ng-container *ngIf="item.subMenu.length === 0; else elseBlock">
    <a [routerLink]="[item.routerLink]" [attr.data-hotkey]="item.dataHotkey" [routerLinkActive]="['active']"><span *ngIf="item.iconClass" [class]="item.iconClass"></span>{{ item.menuName }}</a>
  </ng-container>
  <ng-template #elseBlock>
    <a href="#" [attr.data-hotkey]="item.dataHotkey"><span *ngIf="item.iconClass" [class]="item.iconClass"></span>{{ item.menuName }}</a>
    <ul class="v-menu" data-role="dropdown">
      <app-sub-menu [menuData]="item.subMenu" *ngIf="item.subMenu && item.subMenu.length > 0"></app-sub-menu>
    </ul>
  </ng-template>
</li>
