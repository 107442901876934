import {Component, Inject} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../../auth/auth.service";
import {Router} from "@angular/router";
import {interval, Subscription} from "rxjs";
import {AppSettings, SharedModule} from "../../shared/shared.module";
declare var Metro:any;
declare var $:any;

class SharedFunctions {
}

@Component({
  selector: 'app-viewauction',
  templateUrl: './viewauction.component.html',
  styleUrls: ['./viewauction.component.css']
})

export class ViewAuctionComponent {

  updateSubscription: Subscription = new Subscription;
  userInfo: any;
  loading: boolean = true;
  AuctionOpenItemsNew: any;

  constructor(private http: HttpClient,  @Inject('BASE_URL')private baseUrl: string,public sharedFunctions: SharedModule, public auth:AuthService,public router: Router) {
    this.updateSubscription = interval(AppSettings.STANDARD_PAGE_REFRESH_MS).subscribe((val) => { this.ngOnInit()});

  }

  ngOnInit() {

    this.http.get<any>(this.baseUrl + 'Grain/Auction/GetAuctionPrivileges').subscribe({
      next: (result: any) => {
        this.userInfo = result;

      },
      error: (error: any) => {
        console.error(error);

      }
    });


    this.http.get<any>(this.baseUrl + 'Grain/Auction/GetAllOpenAuctions').subscribe({
      next: (result: any) => {
        this.AuctionOpenItemsNew = result;
        this.loading = false;

      },
      error: (error: any) => {
        console.error(error);
        this.loading = false;
      }
    });
  }


  agreeToTerms() {
    this.http
      .put(this.baseUrl + 'Grain/Auction/AcceptTerms',"")
      .subscribe({
        next: (response) => this.ngOnInit(),
        error: (error) => this.sharedFunctions.metroAlert("There was an error and your changes have not been saved<br/><br/>" + error.message, "Failed", true),
      });
  }
}

