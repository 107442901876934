
<ng-container *ngIf="userInfo.auctionAccepted == 'N'">
    <div style="text-align: center">
        <h2>Welcome...</h2>
        <h4>Please confirm you agree with the terms and conditions</h4>
        <a [routerLink]="['/grain/auction','terms']">To view the terms and conditions please click here</a><br/><br/>
        <button class="button primary" (click)="agreeToTerms()">I agree</button>
    </div>
</ng-container>
<ng-container *ngIf="userInfo.auctionAccepted == 'Y'">
    <br/>
    <div class="row flex-align-start">

        <table *ngIf="!loading" class="table striped row-hover table-border" data-role="table" >
            <thead>
            <tr>
                <th>Auction ID</th>
                <th>Total (tonnes)</th>
                <!--<th>No. Of Lots</th>
                <th>Lot Size</th> -->
                <th>Current Bid</th>
                <th>Reserve Price</th>
                <th>Buy It Now</th>
                <th>Seller</th>
                <th>End Date</th>
                <td>Auction Status</td>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of AuctionOpenItemsNew">
                <td>{{item.auctionDisplayID}}</td>
                <td>{{item.auctionTotalSpace > 0 ? item.auctionTotalSpace : '-'}}</td>
               <!-- <td>{{item.auctionTotalLots > 0  ? item.auctionTotalLots : '-'}}</td>
                <td>{{item.auctionTotalLots > 0 ? item.auctionLotSize : '-'}}</td> -->
                <td>&pound;{{item.auctionCurrentBid > 0 ? item.auctionCurrentBid.toFixed(2) : ' -' }}</td>
                <!--<td>&pound;{{item.auctionReserve > 0 ? item.auctionReserve.toFixed(2) : ' -' }}</td>-->
                <td>&pound;{{item.auctionShowReserve =="Y" ? item.auctionReserve.toFixed(2) : ' -' }}</td>
                <td>&pound;{{item.auctionBuyItNow > 0 ? item.auctionBuyItNow.toFixed(2) : ' -'}}</td>
                <td>{{item.auctionDisplayName == "Y"? item.memberTradingName : ''}}</td>
                <td>{{item.auctionEnd | date:'dd-MMM-Y'}}</td>
                <!--<td>Bid not placed, highest bidder,outbid, bidding not available, indiviudal to user logged in.</td>-->
                <td>{{item.auctionCurrentBid>0 ? 'Bid has been placed' : 'Bid not placed'}}</td>
            </tr>
            </tbody>
        </table>
    </div>
</ng-container>



