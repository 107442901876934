
<app-no-permission  *ngIf="userType == 'STANDARD_USER'"></app-no-permission>
<ng-container *ngIf="userType == 'ADMINISTRATOR'">
    <ul class="v-menu border bd-light" data-role="ripple" data-ripple-target="li">
        <li class="menu-title">General</li>
        <li><a [routerLink]="['/']" [routerLinkActive]="['active']"><span class="mif-home icon"></span> Home</a></li>
    </ul>    

    <h1>Sites</h1>

    <table *ngIf="dataLoaded" class="table striped row-hover table-border" >
        <thead>
        <tr>
          <th>Site Name</th>
          
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of allSites" >
          <td>{{item.desc}}</td>
          <td>
            <button (click)="changeSite(item.desc)"class="button primary">Select</button>
          </td>
        </tr>
        </tbody>
      </table>
</ng-container>