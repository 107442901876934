<div class="colspan-12">
  <div class="row">
    <div class="cell-3">
      <div id="calendar" data-role="calendar" class="compact" data-multi-select="false" data-picker-mode="true" data-buttons="today"
           data-locale="en-GB"
           data-show-header="false"
      ></div>
    </div>
    <div class="cell-9">
      <app-todays-loads>Loading...</app-todays-loads>
    </div>
  </div>
</div>

