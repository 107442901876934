<div class="row flex-align-start">
  <div class="row">
    <div class="cell"><h4>{{dateLabel}} <ng-container *ngIf="this.dateLabel != 'Todays Loads:'">{{selectedDate | date:'dd/MM/yyyy'}}</ng-container></h4></div>
    <div class="container">
      <table class="table striped table-border mt-4 subcompact" data-role="table" id="simpleloads" data-rows="10" data-rows-steps="10,25" data-pagination="true" data-show-search="true">
        <thead>
        <tr>
          <th>Weighbridge Ref 1</th>
          <th>Movement No.</th>
          <th>Variety</th>
          <th>Vehicle</th>
          <th>NETT(tonnes)</th>
          <th>Storage(tonnes)</th>
          </tr>
        </thead>
        <tbody row-hover >
        </tbody>
      </table>
    </div>
  </div>
</div>
