import {Component, ElementRef, Inject, Input, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router, ActivatedRoute} from "@angular/router";
import {AppSettings, SharedModule} from "../../shared/shared.module";
declare var Metro:any;
@Component({
  selector: 'app-auction-bid',
  templateUrl: './auction-bid.component.html',
  styleUrls: ['./auction-bid.component.css']
})
export class AuctionBidComponent {
  @ViewChild('bidAmountBuy') bidAmountBuy!: ElementRef;
  @ViewChild('auctionItemIDBuy') auctionItemIDBuy!: ElementRef;
  @ViewChild('bidTonesBuy') bidTonesBuy!: ElementRef;
  @ViewChild('bidLotsBuy') bidLotsBuy!: ElementRef;
  @ViewChild('bidLimitBuy') bidLimitBuy!: ElementRef;
  
  selectedValue: any;
  selectedIsForLots: any;
  selectedNoOfLots: any;
  selectedReserve: number = 0;
  selectedCurrentBid: any = null;
  selectedTotalTones:number = 0;
  selectedBIN : number = 0;
  auctionIDForSave : any = null;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string,private router: Router,public sharedFunctions: SharedModule,private route: ActivatedRoute) {

  }
  ngOnInit(): void {
    const storedJsonString = localStorage.getItem('bidItem') ?? '';
    const retrievedObject = JSON.parse(storedJsonString);

    this.selectedIsForLots = retrievedObject.auctionIsInLots == 'Y';
    this.selectedNoOfLots = retrievedObject.auctionTotalLots;
    this.selectedTotalTones = retrievedObject.auctionTotalSpace;
    this.selectedValue = retrievedObject.auctionItemID;
    this.selectedReserve = retrievedObject.auctionReserve;
    this.selectedCurrentBid = retrievedObject.auctionCurrentBid;
    this.selectedBIN = retrievedObject.auctionBuyItNow;

  }

  cancelForm() {
    this.router.navigate(["grain","auction","buy"]);
  }

  validate(formData: FormData) {
    let errorMessage:string = "";
    if ((parseInt(formData.get("bidAmount")?.toString() ?? "0") ?? 0) <= 0){
      errorMessage = "You must enter a positive value for your bid amount.";
    } else if ((parseInt(formData.get("bidAmount")?.toString() ?? "0") ?? 0) < AppSettings.MIN_BID_AMOUNT && !this.selectedIsForLots) {
      errorMessage = "The bid amount must be greater or equal to " + AppSettings.MIN_BID_AMOUNT;
    } else if ((parseInt(formData.get("bidAmount")?.toString() ?? "0") ?? 0) > AppSettings.MAX_BID_AMOUNT && !this.selectedIsForLots) {
      errorMessage = "The bid amount must be less than or equal to " + AppSettings.MAX_BID_AMOUNT;
    } else if ((parseInt(formData.get("bidAmount")?.toString() ?? "0") ?? 0) < this.selectedReserve && !this.selectedIsForLots) {
      errorMessage = "The bid amount must be greater than the Reserve";
    }else if ((parseInt(formData.get("bidTones")?.toString() ?? "0") ?? 0) <= 0 && !this.selectedIsForLots) {
      errorMessage = "Please enter the amount of tonnage you wish to bid for.";
    }else if ((parseInt(formData.get("bidTones")?.toString() ?? "0") ?? 0) < AppSettings.MIN_BID_TONNES && !this.selectedIsForLots) {
      errorMessage = "The tonnage entered is less than the site minimum(" + AppSettings.MIN_BID_TONNES + ")";
    }else if ((parseInt(formData.get("bidTones")?.toString() ?? "0") ?? 0) > AppSettings.MAX_BID_TONNES && !this.selectedIsForLots) {
      errorMessage = "The tonnage entered is greater than site maximum (" + AppSettings.MAX_BID_TONNES + ")";
    }else if ((parseInt(formData.get("bidLots")?.toString() ?? "0") ?? 0) <= 0 && this.selectedIsForLots) {
      errorMessage = "Please enter the number of lots you are bidding for.";
    } else if ((parseInt(formData.get("bidLimit")?.toString() ?? "0") ?? 0) < 0){
      errorMessage = "Your max bid amount must be zero or a positive number.";
    } else if (this.selectedIsForLots && parseInt(formData.get("bidLots")?.toString() ?? "0") > this.selectedNoOfLots){
      errorMessage = "You have enter to many lots, please pick a number lower or equal to " + this.selectedNoOfLots;
    } else if (!this.selectedIsForLots && parseInt(formData.get("bidTones")?.toString() ?? "0") > this.selectedCurrentBid.auctionTotalSpace){
      errorMessage = "You have enter to many Tonnes, please pick a number lower or equal to " + this.selectedCurrentBid.auctionTotalSpace;
    }else if ((parseInt(formData.get("bidLimit")?.toString() ?? "0") ?? 0)  < this.selectedReserve) {
      errorMessage = "Your maximum bid is less than the reserve, not able to save. Reserve is " + this.selectedReserve;
    } else if ((parseInt(formData.get("bidAmount")?.toString() ?? "0") ?? 0)  < this.selectedCurrentBid) {
      errorMessage = "Your maximum bid is less than the current bid. The current bid is currently " + this.selectedCurrentBid;
    }  else if (!$("#acceptTerms-buy").is(":checked")){
      errorMessage = "You must accept the terms and conditions before continuing.";
    }

    return errorMessage;
  }
  saveBidRequest() {
    let formData:FormData = new FormData();
    var bid:number = this.bidAmountBuy.nativeElement.value != '' ? this.bidAmountBuy.nativeElement.value : 0;
    this.auctionIDForSave = this.auctionItemIDBuy.nativeElement.value;

    formData.set("auctionItemID",this.auctionItemIDBuy.nativeElement.value);
    formData.set("bidAmount", this.bidAmountBuy.nativeElement.value != '' ? this.bidAmountBuy.nativeElement.value : 0);
    formData.set("bidTones", this.bidTonesBuy.nativeElement.value != '' ? this.bidTonesBuy.nativeElement.value : 0);
    formData.set("bidLots",this.bidLotsBuy.nativeElement.value != '' ? this.bidLotsBuy.nativeElement.value : 0);
    formData.set("bidLimit",this.bidLimitBuy.nativeElement.value != '' ? this.bidLimitBuy.nativeElement.value : 0);
    formData.set("isForLots",this.selectedIsForLots ? "true" : "false");

    let errorString = this.validate(formData);

    if (errorString.length == 0) {
      if ((this.bidAmountBuy.nativeElement.value > this.selectedBIN) && this.selectedBIN > 0)
      {
        this.sharedFunctions.metroBidYesNo("Place Bid", "Your bid is greater than the Buy It Now Price, Do you want to Buy it Now rather than Bid?", this, 
                                            this.saveBINAuction, this.saveBIDAuction)
      }
      else
      {
        this.http
          .post(this.baseUrl + 'Grain/Auction/SaveBidRequest', formData)
          .subscribe({
            next: (response) => this.saveFinished(),
            error: (error) => this.sharedFunctions.metroAlert("There was an error and your changes have not been saved<br/><br/>" + error.message, "Failed", true),
          });
      }
    }else{
      this.sharedFunctions.metroAlert(errorString, "The dialog contains errors", true);
    }
  }

  saveFinished(){
    Metro.dialog.close('#modalNewAuction-buy');
    
    if ((this.bidLimitBuy.nativeElement.value > this.selectedBIN) && this.selectedBIN > 0)
    {
      this.sharedFunctions.metroAlert("Your maximum bid is greater than the Buy It Now Price, you should consider using Buy It Now. Your changes have been saved","Success",false);
    }
    else
    {
      this.sharedFunctions.metroAlert("Your changes have been saved","Success",false);
    }
    this.router.navigate(["/grain/auction/buy/"]);
  }

  saveBINAuction (parent:any){
    if (parent != null)
    {
      let formData:FormData = new FormData();
      formData.append("auctionItemID",parent.auctionIDForSave);
    
      parent.http
        .post(parent.baseUrl + 'Grain/Auction/SaveBINRequest', formData)
        .subscribe({
        next: (response: any) => parent.completeBIN(),
        error: (error: { message: string; }) => parent.sharedFunctions.metroAlert("There was an error and your changes have not been saved<br/><br/>" + error.message, "Failed", true),
      });
      
    }

  }

  saveBIDAuction (parent:any){
    if (parent != null)
    {
      let formData:FormData = new FormData();
      var bid:number = parent.bidAmountBuy.nativeElement.value != '' ? parent.bidAmountBuy.nativeElement.value : 0;
    
      formData.set("auctionItemID",parent.auctionItemIDBuy.nativeElement.value);
      formData.set("bidAmount", parent.bidAmountBuy.nativeElement.value != '' ? parent.bidAmountBuy.nativeElement.value : 0);
      formData.set("bidTones", parent.bidTonesBuy.nativeElement.value != '' ? parent.bidTonesBuy.nativeElement.value : 0);
      formData.set("bidLots",parent.bidLotsBuy.nativeElement.value != '' ? parent.bidLotsBuy.nativeElement.value : 0);
      formData.set("bidLimit",parent.bidLimitBuy.nativeElement.value != '' ? parent.bidLimitBuy.nativeElement.value : 0);
      formData.set("isForLots",parent.selectedIsForLots ? "true" : "false");
      
      
      parent.http
      .post(parent.baseUrl + 'Grain/Auction/SaveBidRequest', formData)
      .subscribe({
        next: (response: any)  => parent.saveFinished1(),
        error: (error: { message: string; }) => parent.sharedFunctions.metroAlert("There was an error and your changes have not been saved<br/><br/>" + error.message, "Failed", true),
      });
      
    }
  }

  completeBIN(){
    Metro.dialog.close('#modalNewAuction-buy');
    this.sharedFunctions.metroAlert("Thank you. Your buy it now request has been accepted.", "Accepted", false);
    this.router.navigate(["/grain/auction/buy/"]);

  }

  saveFinished1(){
    Metro.dialog.close('#modalNewAuction-buy');
    this.sharedFunctions.metroAlert("Your changes have been saved","Success",false);
    this.router.navigate(["/grain/auction/buy/"]);
  }
}
