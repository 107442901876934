
<app-no-permission  *ngIf="userType == 'STANDARD_USER'"></app-no-permission>
<ng-container *ngIf="userType == 'ADMINISTRATOR'">
    <ul class="h-menu border bd-light" data-role="ripple" data-ripple-target="li">
        <li><a [routerLink]="['/']" [routerLinkActive]="['active']"><span class="mif-home icon"></span>Home</a></li>    
        <li><a [routerLink]="['/milling/createuser']" [routerLinkActive]="['active']"><span class="mif-tools icon"></span>Create User</a></li>
    </ul>    

    <h1>System Users</h1>

    <table *ngIf="dataLoaded" class="table striped row-hover table-border" >
        <thead>
        <tr>
          <th>User Name</th>
          <th>User Email</th>
          <th>User Type</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of allUsers" >
          <td>{{item.userName}}</td>
          <td>{{item.userEmail}}</td>
          <td>{{item.userType}}</td>
          <td>
            <button (click)="deleteUser(item.id)"class="button primary">Delete</button>
          </td>
        </tr>
        </tbody>
      </table>
</ng-container>