import '../globals';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing-module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common'
import { AppComponent } from './app.component';
import { TelerikReportingModule } from '@progress/telerik-angular-report-viewer';
import { LoginformComponent } from './loginform/loginform.component';
import { ErrorPageComponent } from './theme/error-page/error-page.component';
import { NavbarComponent } from './theme/navbar/navbar.component';
import { HeaderComponent } from './theme/header/header.component';
import { FooterComponent } from './theme/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AuthInterceptor } from "./auth/auth.module";
import { DetailTrainComponent } from './rail/detail-train/detail-train.component';
import { DndDirectiveDirective } from './directives/dnd.directive.directive';
import { ProgressComponent } from './progress/progress.component';
import { SharedModule } from './shared/shared.module';
import { MillingComponent} from './milling/home/home.component'
import { RailComponent } from './rail/home/home.component';
import { GrainComponent } from './grain/home/home.component';
import { WeighbridgeComponent } from './milling/weighbridge/weighbridge.component';
import { StartstopComponent } from './milling/startstop/startstop.component';
import { GrnComponent } from './milling/grn/grn.component';
import { WeightOnComponent } from './milling/weight-on/weight-on.component';
import { WeightOffComponent } from './milling/weight-off/weight-off.component';
import { WeightOnOffComponent } from './milling/weight-on-off/weight-on-off.component';
import { RelocationComponent } from './milling/relocation/relocation.component';
import { StockadjustComponent } from './milling/stockadjust/stockadjust.component';
import { PackinglineComponent } from './milling/packingline/packingline.component';
import { ReportviewerComponent } from './shared/reportviewer/reportviewer.component';
import { UserImitate } from './shared/userImitate';

import { TestingComponent } from './milling/testing/testing.component';
import { ShiftrecordsComponent } from './milling/shiftrecords/shiftrecords.component';
import { PlantdataComponent } from './milling/plantdata/plantdata.component';
import { PackingdataComponent } from './milling/packingdata/packingdata.component';
import { AnalysisComponent } from './milling/analysis/analysis.component';
import { LocationComponent } from './milling/location/location.component';
import { AnalysisAddComponent } from './milling/analysis-add/analysis-add.component';
import { ActiveconnectionsComponent } from './milling/activeconnections/activeconnections.component';
import { SubMenuComponent } from './main-menu/sub-menu/sub-menu.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { AuctionBuyComponent } from './grain/auction-buy/auction-buy.component';
import { AuctionSellComponent } from './grain/auction-sell/auction-sell.component';
import { AuctionTableComponent } from "./grain/auction-table/auction-table.component";
import { AuctionCreateComponent } from './grain/auction-create/auction-create.component';
import { AuctionBidComponent } from './grain/auction-bid/auction-bid.component';
import { AuctionTermsComponent } from './grain/auction-terms/auction-terms.component';
import {ViewAuctionComponent} from "./grain/viewauction/viewauction.component";
import { SwitchUserComponent } from './grain/switch-user/switch-user.component';
import { DayreportComponent } from './milling/reports/dayreport/dayreport.component';
import { RipandtipComponent } from './milling/ripandtip/ripandtip.component';
import { RepackComponent } from './milling/repack/repack.component';


import { DailyLoadsComponent } from './grain/daily-loads/daily-loads.component';
import { TodaysLoadsComponent } from './grain/home/components/todays-loads/todays-loads.component';
import { DetailedLoadComponent } from './grain/detailed-load/detailed-load.component';
import { ShowSummaryComponent } from './grain/show-summary/show-summary.component';
import { UserComponent } from './milling/user/user.component';
import { NoPermissionComponent } from './theme/no-permission/no-permission.component';
import { UsercreateComponent } from './milling/usercreate/usercreate.component';
import { StockentryComponent } from './milling/stockentry/stockentry.component';
import { SelectsiteComponent } from './rail/selectsite/selectsite.component';
import { KelgreyComponent } from './milling/kelgrey/kelgrey.component';
import { SacklabelComponent } from './milling/kelgrey/sacklabel/sacklabel.component';
import { PalletlabelComponent } from './milling/kelgrey/palletlabel/palletlabel.component';
import { BaglabelComponent } from './milling/kelgrey/baglabel/baglabel.component';
import { ResendComponent } from './milling/kelgrey/resend/resend.component';
import { Dayreport2Component } from './milling/reports/dayreport2/dayreport2.component';
import { DetailCompleteComponent } from './rail/detail-complete/detail-complete.component';
import { KilnAbortComponent } from './milling/kiln-abort/kiln-abort.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginformComponent,
        ErrorPageComponent,
        NavbarComponent,
        HeaderComponent,
        FooterComponent,
        HomeComponent,
        DetailTrainComponent,
        MillingComponent,
        RailComponent,
        GrainComponent,
        WeighbridgeComponent,
        StartstopComponent,
        GrnComponent,
        WeightOnComponent,
        WeightOffComponent,
        WeightOnOffComponent,
        RelocationComponent,
        StockadjustComponent,
        PackinglineComponent,
        ReportviewerComponent,
        TestingComponent,
        ShiftrecordsComponent,
        PlantdataComponent,
        PackingdataComponent,
        AnalysisComponent,
        LocationComponent,
        AnalysisAddComponent,
        ActiveconnectionsComponent,
        SubMenuComponent,
        MainMenuComponent,
        AuctionBuyComponent,
        AuctionSellComponent,
        AuctionTableComponent,
        AuctionCreateComponent,
        AuctionBidComponent,
        AuctionTermsComponent,
        ViewAuctionComponent,
        SwitchUserComponent,
        DayreportComponent,
        RipandtipComponent,
        DailyLoadsComponent,
        TodaysLoadsComponent,
        DetailedLoadComponent,
        ShowSummaryComponent,
        RepackComponent,
        UserComponent,
        NoPermissionComponent,
        UsercreateComponent, StockentryComponent, SelectsiteComponent, KelgreyComponent, SacklabelComponent, PalletlabelComponent, BaglabelComponent,
        ResendComponent, Dayreport2Component, DetailCompleteComponent, KilnAbortComponent

    ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TelerikReportingModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UserImitate, multi: true },
    {provide: LocationStrategy, useClass: HashLocationStrategy}
            ],
  bootstrap: [AppComponent],
})

export class AppModule { }
