import {Component, Inject} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { AuthService } from 'src/app/auth/auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
declare var $:any;


@Component({
  selector: 'app-grain-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class GrainComponent {

  memberInfo: any = {};
  memberAddress: any;
  grainOutletActivated: boolean = false;

  constructor(private http: HttpClient,  @Inject('BASE_URL')private baseUrl: string, public auth:AuthService, public shared:SharedModule, private _router:Router,private route: ActivatedRoute) {
    this.route.url.subscribe((segments) => {
      this.grainOutletActivated = segments.some((segment) => segment.path === 'grain');
    });
    
  }

  ngOnInit() {
    this.http.get<any>(this.baseUrl + 'Grain/Auction/GetAuctionInfo').subscribe({
      next: (result: any) => {
        this.memberInfo = result.memberInfo;
        this.memberAddress = result.memberAddress;
      },
      error: (error: any) => {

      }
    });

  }


}
