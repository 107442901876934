<ng-container *ngIf="_dataLoaded">
<div class="row flex-align-start">
  <div class="row">
    <div class="cell"><h4>{{dateLabel}}</h4></div>
    <div class="container">
      <table class="table striped table-border mt-4" data-role="table" id="{{_id}}" data-pagination="true" data-show-search="true">
        <thead>
        <tr>
          <th class="sortable-column sort-asc">Auction No.</th>
         <!-- <th class="sortable-column sort-asc">Lot Size</th>
          <th class="sortable-column sort-asc">No. Of Lots</th>
        -->
          <th >Total(tonnes)</th>
          <th >Auction Start</th>
          <th >Auction End</th>
          
        </tr>
        </thead>
        <tbody row-hover >
          <tr *ngFor="let item of _rows; let i = index">
            <td>{{item.auctionDisplayID }}</td>
            <!--<td>{{item.auctionLotSize}}</td>
            <td>{{item.auctionTotalLots}}</td> -->
            <td>{{item.auctionTotalSpace}}</td>
            <td>{{item.auctionStart | date:'dd-MMM-Y'}}</td>
            <td>{{item.auctionEnd | date:'dd-MMM-Y'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</ng-container>
