<app-no-permission  *ngIf="userType == 'STANDARD_USER'"></app-no-permission>
<ng-container *ngIf="userType == 'ADMINISTRATOR'">
    <ul class="h-menu border bd-light" data-role="ripple" data-ripple-target="li">
        <li><a [routerLink]="['/']" [routerLinkActive]="['active']"><span class="mif-home icon"></span>Home</a></li>    
    </ul>

    <h1>Create User</h1>

<form>
    <div class="row mb-2">
        <label class="cell-sm-2">User Name</label>
        <div class="cell-sm-10">
            <input id="userName" type="text" class="metro-input" [(ngModel)]="userName" [ngModelOptions]="{standalone: true}">
        </div>
    </div>

    <div class="row mb-2">
        <label class="cell-sm-2">User Email</label>
        <div class="cell-sm-10">
            <input id="userEmail" type="text" class="metro-input" [(ngModel)]="userEmail" [ngModelOptions]="{standalone: true}">
        </div>
    </div>

    <div class="row mb-2">
        <label class="cell-sm-2">Password</label>
        <div class="cell-sm-10">
            <input id="userPassword" type="text" class="metro-input" [(ngModel)]="userPassword" [ngModelOptions]="{standalone: true}">
        </div>
    </div>
    
    <div class="row mb-2">
        <label class="cell-sm-2">User Type</label>
        <div class="cell-sm-10">
        <select data-role="select" id="userType"   [(ngModel)]="selectedUserType" [ngModelOptions]="{standalone: true}">
            <option *ngFor="let c of userTypes" [ngValue]="c.id">{{c.name}}</option>
        </select>
        </div>
    </div>

    
   

    <div class="row">
        <div class="cell">
            <button class="button primary" (click)="saveFormData()">Save</button>
        </div>
    </div>
</form>

</ng-container>
