import { Component } from '@angular/core';
declare var $: any;
declare var Metro: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  copyrightYear = new Date().getFullYear();
  ngOnInit() {
    $(document).keyup(function (e: any) {
      if (e.key === "Escape") { // escape key maps to keycode `27`
        //alert("Escape Pressed");
        $("[data-role=\"dialog\"]").each(function (i: number, obj: any) {

          let plug = Metro.getPlugin(obj, "dialog");
          if (plug.isOpen('#modalNewBid')) {
            plug.close();
          }

        });
      }
    });
  }
}
