<ng-container *ngIf="!dataLoaded">
  <div id="loading" style="width:100%; text-align: center"><br/><br/><small>Please Wait, we're getting your
      data...</small><br/><br/><img src="assets/images/spinner.svg"></div>
  </ng-container>
 
 


<ng-container *ngIf="dataLoaded">
  <ng-container *ngIf="userType == 'ADMINISTRATOR'">
    <ul class="h-menu border bd-light" data-role="ripple" data-ripple-target="li">
      <li><a [routerLink]="['/']" [routerLinkActive]="['active']"><span class="mif-home icon"></span>Home</a></li>    
      <li><a [routerLink]="['/rail/selectsite']" [routerLinkActive]="['active']"><span class="mif-train icon"></span>Select Site</a></li>
    </ul>
    <H2> Selected Site : {{siteName}}</H2>
  </ng-container> 


<div class="row">
    <div class="cell flex-align-self-start">
      <div class="row flex-align-end">
        <div class="row">
          <div class="cell"><h4>Trains Despatched:</h4></div>
          <div class="container">
            <table class="table striped table-border mt-4 subcompact  " id="openTrains" data-role="table" data-pagination="true" data-show-search="true">
              <thead>
                <tr>
                <th  >Date Despatched</th>
                <th  >Time Despatched</th>
                <th >Order Reference</th>
                <th >Total Wagons</th>
                <th >Total Weight(tonnes)</th>
                </tr>
              </thead>
              <tbody row-hover >
                <tr *ngFor="let c of openTrains">
                  <td><a href="#/rail/traindetail/{{c.id}}">{{c.dateDespatch}}</a></td><td><a href="#/rail/traindetail/{{c.id}}">{{c.timeDespatch}}</a></td><td><a href="#/rail/traindetail/{{c.id}}">{{c.orderRef}}</a></td><td><a href="#/rail/traindetail/{{c.id}}">{{c.totalWagons}}</a></td><td><a href="#/rail/traindetail/{{c.id}}">{{c.totalWeight}}</a></td>
                  
                </tr>
              </tbody>
            </table>
  
  
          </div>
          
        </div>
        <div class="row">
          <div class="cell"><h4>Trains Completed:</h4></div>
          <div class="container">
            <table class="table striped table-border mt-4 subcompact  " id="closedTrains" data-role="table" data-pagination="true" data-show-search="true">
              <thead>
                <tr>
                <th >Date Despatched</th>
                <th >Time Despatched</th>
                <th > Order Reference</th>
                <th >Total Wagons</th>
                <th >Total Weight(tonnes)</th>
                </tr>
              </thead>
              <tbody row-hover >
                <tr *ngFor="let c of closedTrains">
                  <td>{{c.dateDespatch}}</td><td>{{c.timeDespatch}}</td><td>{{c.orderRef}}</td><td>{{c.totalWagons}}</td><td>{{c.totalWeight}}</td>
                </tr>
              </tbody>
            </table>
  
          </div>
          
        </div>
        
      </div>
    </div>
  
    <!--
      <div class="cell flex-align-self-end">
      <div class="cell"><h4>Location:</h4></div>
      <div class="container">
        <div class="grid">
          <div class="row no-gap">
            <div class="cell-4"><div>Name:</div></div> <div class="cell-5"><div>{{siteName}}</div></div> 
          </div>    
          <div class="row no-gap">
            <div class="cell-4"><div></div></div> <div class="cell-5"><div></div></div> 
          </div>    
          <div class="row no-gap">
            <div class="cell-4"><div></div></div> <div class="cell-5"><div></div></div> 
          </div>    
          <div class="row no-gap">
            <div class="cell-4"><div></div></div> <div class="cell-5"><div></div></div> 
          </div>    
          <div class="row no-gap">
            <div class="cell-4"><div></div></div> <div class="cell-5"><div></div></div> 
          </div>    
        </div>
      </div>
   
    </div> -->
  </div>
</ng-container>