import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
declare var Metro:any;
declare var $:any;


@NgModule({

  imports: [
    CommonModule
  ]
})

export class SharedModule {

  public ConvertKiloToTonne(WeightInKgs:number)
  {
    return (WeightInKgs/1000).toLocaleString(
      undefined, // leave undefined to use the visitor's browser
      // locale or a string like 'en-US' to override it.
      { minimumFractionDigits: 3 }
    );
  };

  public ConvertTonneToKilo(WeightInKgs:number)
  {
    return (WeightInKgs*1000).toLocaleString(
      undefined, // leave undefined to use the visitor's browser
      // locale or a string like 'en-US' to override it.
      { minimumFractionDigits: 0 }
    );
  };

  public ConvertWeightToDisplay(WeightValue: number) {
    let ret =  this.ConvertKiloToTonne(WeightValue);
    return ret;
  }

  public ConvertWeightToDB(WeightValue: number) {
    let ret =  this.ConvertTonneToKilo(WeightValue);
    return ret;
  }

  public GetSystemType () {

    let ret   = localStorage.getItem('systemType');
    return ret;
  }

  public metroAlert(message:string, title:string, error:boolean = false, acceptFunction:any = null){
    let thisObj = this;
    Metro.dialog.create({
      title: title,
      clsDialog: error ? "alert" : "success",
      content: "<div>" + message + "</div>",
      actions: [
        {
          caption: "OK",
          cls: "js-dialog-close",
          onclick: acceptFunction
        }
      ]
    });
  }

  public metroYesNo(title : string, message : string, parent:any=null, acceptFunction:any = null){
    Metro.dialog.create({
        title: title,
        content: "<div>" + message + "</div>",
        actions: [
            {
                caption: "Yes",
                cls: "js-dialog-close alert",
                onclick: function(){
                    acceptFunction(parent);
                }
            },
            {
                caption: "No",
                cls: "js-dialog-close",
                onclick: function(){
                  //Do nothing
                }
            }
        ]
    });

    
}

public metroBidYesNo(title : string, message : string, parent:any=null, acceptFunction:any = null, rejectFunction:any = null){
  Metro.dialog.create({
      title: title,
      content: "<div>" + message + "</div>",
      actions: [
          {
              caption: "Yes",
              cls: "js-dialog-close alert",
              onclick: function(){
                  acceptFunction(parent);
              }
          },
          {
              caption: "No",
              cls: "js-dialog-close",
              onclick: function(){
                rejectFunction(parent);
              }
          }
      ]
  });
}


  public base64ToBlob(base64:string, mimetype:string, slicesize:number = 512) : Blob | MediaSource
  {

    mimetype = mimetype || '';
    slicesize = slicesize || 512;
    var bytechars = atob(base64);
    var bytearrays = [];
    for (var offset = 0; offset < bytechars.length; offset += slicesize) {
      var slice = bytechars.slice(offset, offset + slicesize);
      var bytenums = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        bytenums[i] = slice.charCodeAt(i);
      }
      var bytearray = new Uint8Array(bytenums);
      bytearrays[bytearrays.length] = bytearray;
    }
    return new Blob(bytearrays, {type: mimetype});
  };

  public ReportPath(reportName:string)
  {
    return "d:\\Customer\\Navara\\Reports\\" + reportName;
    //return "//var/www//reports//" + reportName;

  };

  public Escape (html:string) {
    return $("<div>").text(html).html();
  };




}

export class AppSettings {

  public static STANDARD_PAGE_REFRESH_MS = 15000;

  public static MIN_LOT_COUNT = 1;
  public static MAX_LOT_COUNT = 10;

  public static MIN_LOT_SIZE = 30;
  public static MAX_LOT_SIZE = 5000;

  public static MIN_RESERVE_PRICE = 20;
  public static MAX_RESERVE_PRICE = 110;

  public static MIN_BUY_IT_NOW_PRICE = 20;
  public static MAX_BUY_IT_NOW_PRICE = 110;

  public static MIN_BID_AMOUNT = 20;
  public static MAX_BID_AMOUNT = 110;

  public static MIN_BID_TONNES = 30;
  public static MAX_BID_TONNES = 5000;
}


export interface simpleLoadType
{
  trnGrainPassportNumber: string;
  trnDelOrderNumber: string;
  trnVarietyDesc: string;
  trnVehicleReg: string;
  trnNettWeight: number;
  trnMemberStorageWeight: number;
  trnReference:string;

}

export interface menuYears {
  [key: string] : any;
}

export interface Dictionary {
  [key: string] : object;
}

export interface MemberList {
  [key: number] : any;
}


export interface DocumentModel
{
  tttReference:string;
  tttObject:string;

}
export interface SampleDetailModel
{
  ttsDescription:string;
  ttsValue:number;
  tddDeductionWt:number;
  trcNettPrice:number;
  ttsSequence:number;
}

export interface SummaryDetailsModel
{
  trnHarvestYear:string;
  trsReference:string;
  trnSampleNumber:string;
  trnGrainPassportNumber:string;
  trnDtNettWeight:Date;
  trnMemberDesc:string;
  trnMemberCode:string;
  trnVarietyDesc:string;
  trnGradePoolDesc:string;
  trnProductGroupDesc:string;
  storename:string;
  trnHaulierDesc:string;
  trnVehicleReg:string;
  trnNettWeight:number;
  trnTicketNumber:string;
  memaddressadd1:string;
  memaddressadd2:string;
  memaddressadd3:string;
  memaddressadd4:string;
  memaddresscounty:string;
  memaddresstown:string;
  memaddresspcode:string;
  trnMemberStorageWeight:number;
  trnDelOrderNumber:string;
  marketingextdesc:string;
  tctData:string;
}

export interface SummaryTable {
  [key: string] : summaryTable[];
}

export interface _SummaryTable {
  [key:string] : summaryTable[];
}

export interface summaryTable
{
  trnReference: string;
  trnGrainPassportNumber: string;
  trnNettWeight: number;
  trnMemberStorageWeight: number;
  trnContractNumber : string;
  trnCalculatedValue3: string;
  trnDtNettWeight: Date;
  trnCalculatedValue4: string;
  trnCalculatedValue5: string;
  trnMOISTDED: number;
  trnSTORELOSS: number;
  trnSCREENDED: number;
  trnADMIXDED: number;
  trnCalculatedValue1: string;
  trnMoisture : number;
  trnAdmix: number;
  trnErgot: number;
  trnGerm: number;
  trnSPW: number;
  trnPON: number;
  trnRET: number;
  trnSCR: number;
}
