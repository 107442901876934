<div class="dialog-title">Create Your Bid.</div>
<div class="dialog-content scroll-y">
  <form name="edit" id="edit-buy" class="" action="" method="post">
    <div class="form-group">
      <input type="hidden" id="auctionItemID-buy" #auctionItemIDBuy value="{{selectedValue}}">
      <label>Enter your bid amount (£ Per Tonne)</label>
      <input type="number" id="bidAmount-buy" #bidAmountBuy class="myInputs" autocomplete="off" data-role="input"  min={applicationMinBidAmount} max={applicationMaxBidAmount} value="0">
      <ng-container
        *ngIf="selectedIsForLots; then bidlots; else bidTonnes">
      </ng-container>
      <ng-template #bidTonnes>
        <div [hidden]="selectedIsForLots" >
          <label>How many tonne's are you bidding for</label>
          <input type="number" id="bidTones-buy" #bidTonesBuy class="myInputs" autocomplete="off" data-role="input"  min="0" max="{{selectedTotalTones}}" value="{{selectedTotalTones}}" disabled = "1">
        </div>
      </ng-template>
      <ng-template #bidlots>
        <div [hidden]="selectedIsForLots" >
          <label>How many tonne's are you bidding for</label>
          <input type="number" id="bidTones-buy" class="myInputs" autocomplete="off" data-role="input"  min="0" max="{{selectedTotalTones}}" value="0">
        </div>
      </ng-template>

      <div [hidden]="!selectedIsForLots">
        <label>How many lots are you bidding for</label>
        <input type="number" id="bidLots-buy" #bidLotsBuy class="myInputs" autocomplete="off" data-role="input"  min="0" max="{{selectedNoOfLots}}" value="0">
      </div>
      <hr/>
      <div class="cell-12"></div>
          <label>Max bid amount</label><br/>
          <small>Enter a value here that you are happy to pay, your bid will increase automatically until it reaches this limit.</small>
          <input type="number" id="bidLimit-buy" #bidLimitBuy class="myInputs" autocomplete="off" data-role="input"  min="0" max="9999" value="0">
        </div>
   
    <div class="row">
      <div class="cell-12">
        <label>
          <input type="checkbox" id="acceptTerms-buy">
          Please confirm you agree to the Terms and conditions as layout out <a target="_blank" [routerLink]="['/grain/auction/terms']">here</a>
        </label>
      </div>
    </div>
    <div class="dialog-actions">
      <button class="button" type="reset" value="Reset" (click)="cancelForm()">Cancel</button>
      <button class="button primary" (click)="saveBidRequest()">Save</button>
    </div>
  </form>
</div>
