import {Component, Inject} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { AuthService } from 'src/app/auth/auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';
declare var $:any;

@Component({
  selector: 'app-kelgrey',
  templateUrl: './kelgrey.component.html',
  styleUrl: './kelgrey.component.css'
})
export class KelgreyComponent {
  private sm : SharedModule;
  userType : any = "";

  constructor(private http: HttpClient,  @Inject('BASE_URL')private baseUrl: string, public auth:AuthService, public shared:SharedModule, private _router:Router) {
    this.sm = shared;
    this.userType = auth.currentUserValue?.usertype;
  }
}
