import {Component, Inject, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { AuthService } from 'src/app/auth/auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';
declare var $:any;


@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent  implements OnInit{
  dataLoaded = false;
  locations : any;
  private sm : SharedModule;
  
  constructor(private http: HttpClient,  @Inject('BASE_URL')private baseUrl: string, public auth:AuthService, public shared:SharedModule, private _router:Router) {
    this.sm = shared;
  }

  ngOnInit(){
    this.http.get(this.baseUrl + 'Milling/ListLocations').subscribe(
      (data:any) => {
        this.locations = data; 
        this.dataLoaded = true;});

   
      
  }
}
