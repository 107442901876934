import {Component, Inject,  NgIterable, ViewChild, ElementRef, OnInit } from '@angular/core';

import {ActivatedRoute, ParamMap} from "@angular/router";
import {HttpClient, HttpParams} from "@angular/common/http";
import { AuthService } from 'src/app/auth/auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';



@Component({
  selector: 'app-reportviewer',
  templateUrl: './reportviewer.component.html',
  styleUrls: ['./reportviewer.component.css']
})
export class ReportviewerComponent  implements OnInit  {
    public reportName:string | null = "";
    private sm : SharedModule;


    public serviceUrl: string = "";
    public reportPath: string = "";
    public processingMode: string = "";
   
    viewerContainerStyle = {
      position: 'relative',
      width: '1000px',
      height: '800px',
      ['font-family']: 'ms sans serif'
  };   



    constructor(private route: ActivatedRoute,private http: HttpClient, @Inject('BASE_URL')private baseUrl:string, private _router : Router,  public shared:SharedModule) {
      this.sm = shared;
      this.route.paramMap.subscribe((params: ParamMap) => {
        this.reportName = params.get('reportname');
        if (this.reportName) {
          
          this.serviceUrl = this.baseUrl + 'api/ReportViewer';
          this.reportPath = this.sm.ReportPath(this.reportName + '.rdl');
          this.processingMode = "Server";
          
        }
      });
    }


    ngOnInit() {
      
    }
}
