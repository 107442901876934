<app-no-permission  *ngIf="userType == 'STANDARD_USER'"></app-no-permission>
<ng-container *ngIf="userType == 'ADMINISTRATOR'">
<ul class="h-menu border bd-light" data-role="ripple" data-ripple-target="li">
    <li><a [routerLink]="['/']" [routerLinkActive]="['active']"><span class="mif-home icon"></span>Home</a></li>
    <li><a [routerLink]="['/milling/kelgrey/sacklabel']" [routerLinkActive]="['active']"><span class="mif-doc-file icon"></span>Sack Label</a></li>
    <!--<li><a [routerLink]="['/milling/kelgrey/palletlabel']" [routerLinkActive]="['active']"><span class="mif-doc-file icon"></span>Pallet Label</a></li>
    <li><a [routerLink]="['/milling/kelgrey/baglabel']" [routerLinkActive]="['active']"><span class="mif-doc-file icon"></span>Bag Label</a></li>-->
    
</ul>

<div>

    <h1>Welcome to the Kelgrey Label Test Bed</h1>
    <p>Select an option from the menu at the top of the screen.</p>
    
   
</div>
</ng-container>
