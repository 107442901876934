<app-error-page *ngIf="userInfo.auctionVisible == 'N'"></app-error-page>
<ng-container *ngIf="userInfo.auctionVisible == 'Y'">
  <ng-container *ngIf="userInfo.auctionCanBuy == 'N'">
    <br/><br/>
    <ng-container *ngIf="this.hasOutstandingBuy">
      <div style="text-align: center">
        <h3>You have requested access to this area.<br/>The request is awaiting approval.</h3><br/>
      </div>
    </ng-container>
    <ng-container *ngIf="!this.hasOutstandingBuy">
      <div style="text-align: center">
        <h3>You don't have access to this section of the Auction module.</h3><br/>
        <button class="button primary" (click)="requestAccess()">Request Access</button>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="userInfo.auctionCanBuy == 'Y'">
    <ng-container *ngIf="userInfo.auctionAccepted == 'N'">
      <div style="text-align: center">
        <h2>Welcome...</h2>
        <h4>Please confirm you agree with the terms and conditions</h4>
        <a [routerLink]="['/auction','terms']">To view the terms and conditions please click here</a><br/><br/>
        <button class="button primary" (click)="agreeToTerms()">I agree</button>
      </div>
    </ng-container>
    <ng-container *ngIf="userInfo.auctionAccepted == 'Y'">
      <br/>
      <div class="row flex-align-start">

        <table class="table striped row-hover table-border" >
          <thead>
          <tr>
            <th>Auction ID</th>
            <th>Total (tonnes)</th>
          
            <th>Current Bid</th>
            <th>Reserve Price</th>
            <th>Buy It Now</th>
            <th>Seller</th>
            <th>End Date</th>

            <th></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of openAuctions">
            <td>{{item.auctionDisplayID}}</td>
            <td>{{item.auctionDisplayTonnage == "Y" ? item.auctionTotalSpace : '-'}}</td>
          
            <td>&pound;{{item.auctionCurrentBid > 0 ? item.auctionCurrentBid.toFixed(2) : ' -' }}</td>
            <td>&pound;{{item.auctionReserve > 0 ? item.auctionReserve.toFixed(2) : ' -' }}</td>
            <td>&pound;{{item.auctionBuyItNow > 0 ? item.auctionBuyItNow.toFixed(2) : ' -'}}</td>
            <td>{{item.membertradingname}}</td>
            <td>{{item.auctionEnd | date:'dd-MMM-Y'}}</td>

            <td>

              <button *ngIf="item.already_bid == 'N'" class="button primary" (click)="openBidWindow(item)">Place Bid</button>
              <span *ngIf="item.already_bid == 'Y'">Bid Already Placed</span>
            </td>
            <td>

              <button *ngIf="item.auctionBuyItNow > 0 && (item.auctionCurrentBid < item.auctionBuyItNow)" class="button primary" (click)="openBuyItNow(item.auctionItemID)">Buy It Now</button>
              <span *ngIf="(item.auctionCurrentBid > item.auctionBuyItNow)"></span>


            </td>
          </tr>
          </tbody>
        </table>
      </div>

    </ng-container>
  </ng-container>
</ng-container>

