import {Component, Inject} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { AuthService } from 'src/app/auth/auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import {NavigationExtras} from "@angular/router";
import {AppSettings} from "../../shared/shared.module";
import {interval, Subscription} from "rxjs";


@Component({
  selector: 'app-selectsite',
  templateUrl: './selectsite.component.html',
  styleUrl: './selectsite.component.css'
})
export class SelectsiteComponent {

    userType : any = "";
    allSites: any;
    dataLoaded : boolean = false;

    constructor(private http: HttpClient,  @Inject('BASE_URL')private baseUrl: string, public auth:AuthService, public shared:SharedModule, private _router:Router,private route: ActivatedRoute) {
      this.userType = auth.currentUserValue?.usertype;
    }
  
    ngOnInit() {
      if (this.userType == 'ADMINISTRATOR')
      {
          this.http.get<any>(this.baseUrl + 'Rail/ListSites').subscribe({
            next: (result: any) => {
              this.allSites = result;
              this.dataLoaded = true;
            },
            error: (error: any) => {
  
            }
          });
      }
      
    }

    changeSite (siteID : string)
    {
      let formData:FormData = new FormData();
      formData.append("siteID",siteID);
      
     this.http
         .post(this.baseUrl + 'Rail/ChangeSiteForUser', formData)
        .subscribe({
           next: (response) => this.changeFinished(),
            error: (error) => this.shared.metroAlert("There was an error and your changes have not been saved<br/><br/>" + error.message, "Failed", true),
          });


    }

    changeFinished()
    {
      this._router.navigate(['/'])
    }

}
