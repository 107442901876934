import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
declare var Metro:any;
declare var $:any;
@Component({
  selector: 'app-auction-table',
  templateUrl: './auction-table.component.html',
  styleUrls: ['./auction-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuctionTableComponent {
  dateLabel: string = "Active and Future Auctions";
  _id: string = "simpleLoad";
  _rows: any[] = [];
  _dataLoaded: any;
  @Input()
  set title(value: string ) {
    this.dateLabel = value;
  }

  @Input()
  set id(value: string ) {
    this._id = value;
  }
  @Input()
  set rows(value: any[]){
    this._rows = value;
  }

  @Input()
  set dataLoaded(value: boolean) {
    this._dataLoaded = value;
  }



}
